import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Modal, Badge, Checkbox, Form, Input, Select, Radio, Space, TimePicker, DatePicker, Spin, Tree, Tooltip, Drawer } from "antd";
import { LoadingOutlined, SearchOutlined, EditFilled, SyncOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import cwPosLogo from "../../../../assets/images/cwpos-green.png";
import MenuIcon from "../../../../assets/images/menu.png";
import Home from "../../../../assets/images/dashboard.png";
import userIcon from "../../../../assets/images/userIcon.svg";
import Logout from "../../../../assets/images/logout.png";
import data from "../../../../constants/restaurantPos.json";
import TableDineIN from "../SvgComponents/TableDineIn";
import TakeAway from "../SvgComponents/TakeAway";
import TableMenu from "../SvgComponents/TableMenu";
import closeX from "../../../../assets/images/close-x.svg";
import TableCustomer from "../SvgComponents/TableCustomer";
import CashManagementIcon from "../../Retail/SvgComponent/CashManagementIcon";
import SalesHistoryIcon from "../../Retail/SvgComponent/SalesHistoryIcon";
import SyncIcon from "../../Retail/SvgComponent/SyncIcon";
import Logo from "../../../../assets/images/logo-without-bg.png";
import { clock, timeStamp, currentDay } from "../../../../utility/clock";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./style.css";
import Tick from "../../../../assets/images/tick-small.svg";
import { v4 as uuidv4 } from "uuid";
import db from "../../../../database";
import moment from "moment";
import { serverUrl } from "../../../../constants/config";
import Axios from "axios";
// import { socket } from "../../../../socket";
import { SyncData } from "./SyncData";
// import { sendOrder } from "../../../../socket";
import { getOAuthHeaders } from "../../../../constants/oAuthValidation";
import TableLayout from "./TableLayout";
import CashManagement from "../cashManagement";
import { useGlobalContext } from "../../../../lib/auth/globalStore";
import { processSync } from "../../../Sync/syncData";

const { Option } = Select;

const TableManagement = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const history = useHistory();
  const keyboard = useRef();
  const dynamicHeight = 0.5625;
  const [guestsModalFlag, setGuestsModalFlag] = useState(false);
  const [layout, setLayout] = useState("default");
  const [inputFields, setInputFields] = useState({});
  const [recordState, setRecardState] = useState("");
  const [inputName, setInputName] = useState("default");
  const [companyInputName, setCompanyInputName] = useState("default");
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const [tableCards, setTableCards] = useState([]);
  const [reserveFlag, setReserveFlag] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const [radioFlag, setRadioFlag] = useState(false);
  const [companyFlag, setCompanyFlag] = useState(false);
  const [radioValue, setRadioValue] = useState({ value: "all", name: "All" });
  const [dineInFlag, setDineInFlag] = useState("Y");
  const [priorityBtn, setPriorityBtn] = useState(false);
  const [locatedList, setLocatedList] = useState(JSON.parse(localStorage.getItem("locatedList")) !== null ? JSON.parse(localStorage.getItem("locatedList")) : []);
  const [keyboardVisible, setKeyboardVisible] = useState(true);
  const [tableList, setTableList] = useState([]);
  const [companySearchInput, setCompanySeacrhInput] = useState("");
  const keyboardRef = useRef(null);
  const [guestForm] = Form.useForm();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [displayCompanySearch, setDisplayCompanySearch] = useState(false);
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const companySearchInputRef = useRef();
  const [customerSearchResults, setCustomerSearchResults] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [reloadFlag, setReloadFlag] = useState(false);
  const [tableItems, setTableItems] = useState({});
  const [websocket, setwebsocket] = useState([]);
  const [laywayModal, setLaywayModal] = useState(false);
  const [fbOrderLayawayData, setFbOrderLayawayData] = useState([]);
  const [tempLocatedList, setTempLocatedList] = useState([]);
  const [layawayCheck, setLayawayCheck] = useState([]);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  // const [keyValue, setKeyValue] = useState("0");
  const [restaurantSections, setRestaurantSections] = useState([]);
  const [reservedTablesData, setReservedTablesData] = useState([]);
  const [occupiedDataTablesData, setOcupiedTablesData] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());

  const { setKeyValue, keyValue, setSelectedTable, syncLoad, userPreferences } = props;

  let cleanToken;
  const authHeaders = getOAuthHeaders();
  if (authHeaders) {
    cleanToken = authHeaders.access_token;
  }

  const [noOfPersons, setNumberOfPersons] = useState("");
  const handleNumberClick = (number) => {
    setInputValue(number);
    setNumberOfPersons(number);
    form.setFieldsValue({ noOfPersons: number });
  };

  const handleClick = () => {
    setDisplayReserveTablesPopup(false);
    setInputFields("");
    // setKeyboardVisible(false);
    // guestForm.resetFields();
    form.resetFields();
    setGuestsModalFlag(false);
  };

  useEffect(async () => {
    let dineInKey = localStorage.getItem("dineIn") !== null ? localStorage.getItem("dineIn") : "Y";
    setDineInFlag(dineInKey);
    const reservedData = [];
    const occupiedData = [];
    setReserveFlag(JSON.parse(tillData.tillAccess.userAccessController).Reservation);
    // if (dineInKey === "Y") {
    setLoading({ status: true, message: "" });
    localStorage.setItem("dineIn", dineInKey);
    localStorage.setItem("customerName", undefined);
    let tempData = [];
    let data = await db.sectionTables.toArray();
    setSelectData(data);
    setRestaurantSections(data);
    await db.tableData.toArray().then((fetched) => {
      tempData.push(...fetched);
    });
    tempData.sort((a, b) => a.originalIndex - b.originalIndex);
    tempData.map((e) => {
      if (e.statusType === "OPN") {
        e.color = "#a7c957";
        e.noOfPersons = "";
        e.guestName = "";
        e.guestType = "";
        e.referredBy = "";
        e.statusType = "OPN";
        delete e.cart;
        delete e.fbOrder;
        delete e.lines;
        delete e.noOfPersons;
        delete e.waiter;
        delete e.salesRepId;
        delete e.fbOrderId;
        delete e.guestName;
        delete e.guests;
        delete e.guestType;
        delete e.referredBy;
        delete e.fbOrderStatus;
        delete e.id;
        delete e.ordered;
        // delete e.fbtableStatusId;
      } else if (e.statusType === "OCU") {
        occupiedData.push(e);
      } else {
        reservedData.push(e);
      }
    });

    setTableCards(tempData);
    setTableList(tempData);
    setMainTableData(tempData);
    setReservedTablesData(reservedData);
    setOcupiedTablesData(occupiedData);
    await db.tableData.clear();
    await db.tableData.bulkAdd(tempData);
    const handleOrderReceived = async (order) => {
      if (order.tableDetails !== undefined) {
        let allTableData = await db.tableData.toArray();
        let data = [];
        allTableData.map((item) => {
          if (order.tableDetails.cwrFbTableId === item.cwrFbTableId) {
            item = {
              ...order.tableDetails.data,
            };
          }
          if (item.statusType === "OPN") {
            item.color = "#a7c957";
            item.noOfPersons = "";
            item.guestName = "";
            item.guestType = "";
            item.referredBy = "";
            item.statusType = "OPN";
            delete item.cart;
            delete item.fbOrder;
            delete item.lines;
            delete item.noOfPersons;
            delete item.waiter;
            delete item.salesRepId;
            delete item.fbOrderId;
            delete item.guestName;
            delete item.guests;
            delete item.guestType;
            delete item.referredBy;
            delete item.fbOrderStatus;
            delete item.id;
            delete item.ordered;
          }
          data.push(item);
        });
        data.sort((a, b) => a.originalIndex - b.originalIndex);
        setTimeout(async () => {
          await db.tableData.clear();
          await db.tableData.bulkPut(data);
        }, 1000);
        setTableCards(data);
        setTableList(data);
      }
      if (order.fbOrder !== undefined) {
        await db.fbOrderData
          .where("fbOrderId")
          .equals(order?.fbOrder.fbOrderId)
          .toArray()
          .then(async (ordersFetched) => {
            if (ordersFetched.length > 0) {
              await db.fbOrderData.put(order.fbOrder.order, order.fbOrder.fbOrderId);
            } else {
              await db.fbOrderData.put(order.fbOrder.order);
            }
          });
      }
      if (order.fbTableData !== undefined) {
        await db.tableData.clear();
        await db.tableData.bulkPut(order.fbTableData);
      }
      // if(order.fbOrderData !== undefined){
      //   await db.fbOrderData.clear();
      //   await db.fbOrderData.bulkPut(order.fbOrderData);
      // }
      await db.fbOrderData
        .where("fbOrderStatus")
        .equals("CO")
        .and((order) => order.fbOrderSync === "Y")
        .delete();
    };
    setLoading({ status: false, message: "" });
    // socket.on("orderReceived", handleOrderReceived);
    return () => {
      setLoading({ status: false, message: "" });
      // socket.off("orderReceived", handleOrderReceived);
    };
    // }
  }, [syncLoad, updateTable]);

  useEffect(() => {
    if (tillData.tillAccess.salesRep === "N") {
      const syncOrdersInterval = setInterval(() => {
        syncOrders();
      }, 10000);
      return () => {
        clearTimeout(syncOrdersInterval);
      };
    }
    const syncOrdersInterval = setInterval(() => {
      SyncData("", "upsertFbOrder");
    }, 10000);
    return () => {
      clearTimeout(syncOrdersInterval);
    };
  }, []);

  const syncOrders = async () => {
    // let fbOrderData = await db.fbOrderData.toArray();
    // let fbTableData = await db.tableData.toArray();
    // const order = {
    //   fbOrderData: fbOrderData,
    //   fbTableData: fbTableData
    // };
    // sendOrder(order);
    await db.fbOrderData
      .where("fbOrderStatus")
      .equals("CO")
      .and((order) => order.fbOrderSync === "Y")
      .delete();
  };

  const handleSync = () => {
    return new Promise(async (restaurantTablesSuccess, restaurantTablesFailure) => {
      try {
        const paramsInput = {
          query: `query{
            getRestaurantTables(tillId:"${tillData.tillAccess.cwrTill.cwrTillID}"){
              cwrFbsectionId
              sectionName
              posTables{
                cwrFbTableId  
                cSBunitID
                cSClientID
                created
                createdby
                csWindowId
                isactive
                updated
                updatedby
                name
                capacity
                cwrFbFloorId
              }
            }  
          }`,
        };
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${cleanToken}`,
          },
        });
        const { getRestaurantTables } = response.data.data;
        if (response.status === 200 && getRestaurantTables.length > 0) {
          await db.tableData.clear();
          let tempData = [];
          let netWorkerror = false;
          getRestaurantTables.map((e, index) => {
            e.posTables.map((item) => {
              item.title = item.name;
              item.merge = true;
              item.cwrFbsectionId = e.cwrFbsectionId;
              item.sectionName = e.sectionName;
              item.table = item.name;
              item.color = "#a7c957";
              item.cwrFbsectionId = e.cwrFbsectionId;
              item.statusType = "OPN";
              item.originalIndex = index;
              tempData.push(item);
            });
          });
          localStorage.setItem("customerName", undefined);
          setLoading({ status: true, message: "" });
          let data = [];
          await db.tableData.toArray().then((fetched) => {
            tempData.push(...fetched);
          });
          tempData.sort((a, b) => a.originalIndex - b.originalIndex);
          let dineInKey = localStorage.getItem("dineIn") !== null ? localStorage.getItem("dineIn") : "Y";
          setDineInFlag(dineInKey);
          localStorage.setItem("dineIn", dineInKey);
          let occupiedData = [];
          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query {
                  getFBOrder(tableId:null,
                  status: "IP") {
                  cSBunitID
                  cSClientID
                  isactive
                  fbOrderId
                  fbTableId
                  fbSectionId
                  sOrderId
                  orderNo
                  guestName
                  guestType
                  referredBy
                  date1
                  status
                  salesRepId
                  guests
                  isCanceled
                  lines{
                  isCanceled
                  cSBunitID
                  cSClientID
                  isactive
                  fbOrderId
                  fbOrderLineId
                  mPoductId
                  mBatchId
                  description
                  csUomId
                  csTaxId
                  line
                  qty
                  unitPrice
                  listPrice
                  lineNet
                  lineTax
                  lineGross
                  sOrderLineId
                  isOrdered
                  meta{
                    key
                    value
                    fbOrderLineMetaId
                    }
                  }
                  }
              }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          })
            .then((FbOrderData) => {
              if (FbOrderData?.data?.data?.getFBOrder.length > 0) {
                FbOrderData.data.data.getFBOrder.map(async (ele) => {
                  tempData.map(async (e) => {
                    if (e && e.cwrFbTableId === ele.fbTableId) {
                      e.ordered = "Y";

                      // ---------------------------------------//
                      let tempCart = { total: 0, tax: 0 };
                      let newArray = [];
                      let sOrderID = null;
                      let flag = "Y";
                      let price = 0;
                      let tax = 0;
                      let manualDiscountApplied = 0;
                      ele.lines?.map((item, index) => {
                        let obj = JSON.parse(item.meta[0].value);
                        if (item.isCanceled === "Y") {
                          obj.nettotal = 0;
                          obj.realPrice = 0;
                          obj.salePrice = 0;
                          obj.taxAmount = 0;
                          obj.total = 0;
                          obj.canceled = "Y";
                        } else {
                          flag = "N";
                        }
                        let manulaDiscount = 0;

                        if ((index === ele.lines.length - 1 && flag === "N") || tillData.tillAccess.salesRep === "N") {
                          sOrderID = obj.sOrderID;
                          manulaDiscount = obj.manualDiscountApplied;
                          tempCart.totalQty = obj.totalQty;
                          tempCart.customer = obj.customer;
                        }
                        manualDiscountApplied = obj.manualDiscountApplied;
                        obj.total = obj.total - obj.discount;
                        tempCart.tax += obj.weight * obj.taxAmount;
                        tempCart.total += obj.weight * obj.realPrice - obj.weight * obj.discount;
                        tempCart.manualDiscountApplied = manulaDiscount;
                        tax = tax + obj.weight * obj.taxAmount;
                        price = price + obj.weight * obj.realPrice;
                        obj.tax = item.csTaxId;
                        obj.order = item.isOrdered;
                        newArray.push(obj);
                      });
                      if (tempCart.customer === undefined) tempCart.customer = tillData.tillAccess.csBunit.b2cCustomer;
                      let cartObj = {
                        ...tempCart,
                        items: [...newArray],
                        paid: 0,
                        change: 0,
                        roundOff: 0,
                        payments: [],
                        redemptionPoints: 0,
                        accumulationPoints: 0,
                        creditAmount: 0,
                        sOrderID: sOrderID,
                        discount: manualDiscountApplied,
                      };
                      let fbOrder = {
                        ...ele,
                        cwrFbTableId: ele.fbTableId,
                        isactive: ele.isactive ? "Y" : "N",
                        table: e.table,
                        color: e.color,
                        ordered: e.ordered,
                        waiter: ele.salesRepId,
                        isCanceled: ele.isCanceled,
                        cart: cartObj,
                        fbOrderStatus: "IP",
                        fbSectionId: ele.fbSectionId,
                        noOfPersons: ele.guests,
                        creatdeTime: ele.date1,
                      };

                      await db.fbOrderData
                        .where("fbOrderId")
                        .equals(ele.fbOrderId)
                        .toArray()
                        .then(async (ordersFetched) => {
                          if (ordersFetched.length > 0) {
                            await db.fbOrderData.update(fbOrder.fbOrderId, fbOrder);
                          } else {
                            await db.fbOrderData.add(fbOrder);
                          }
                        });
                      // ---------------------------------------//
                    }
                  });
                });
              }
            })
            .catch((err) => {
              netWorkerror = true;
            });

          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query {
                  getTableStatus(tableId: null) {
                  cSBunitID
                  cSClientID
                  isactive
                  fbTableId
                  fbSectionId
                  guestName
                  guestType
                  referredBy
                  status
                  salesRepId
                  guests
                  fbtableStatusId
                  }
              }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          })
            .then(async (response) => {
              if (response.data.data.getTableStatus.length > 0) {
                occupiedData = [...response.data.data.getTableStatus];
                await Axios({
                  url: serverUrl,
                  method: "POST",
                  data: {
                    query: `query {getTableReservation(tableId: null, date : "${moment(new Date()).format(userPreferences.dateFormat)}") {
                      cSBunitID
                      cSClientID
                      isactive
                      fbTableReservationId
                      fbTableId
                      fbSectionId
                      customerName
                      noOfPeople
                      contactNo
                      guestType
                      referredBy
                      reservationTime
                      reservationDate
                      }
                  }`,
                  },
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${cleanToken}`,
                  },
                }).then((res) => {
                  if (res.data.data.getTableReservation.length > 0) {
                    occupiedData.map((e) => {
                      res.data.data.getTableReservation?.map((ele) => {
                        if (e.fbTableId === ele.fbTableId) {
                          e.reservationTime = ele.reservationTime;
                        }
                      });
                    });
                  }
                });
              }
            })
            .catch((err) => {
              setLoading({ status: false, message: "" });
              netWorkerror = true;
            });
          setSelectData(data);
          setRestaurantSections(data);
          tempData.map((e) => {
            if (occupiedData.length > 0) {
              occupiedData?.map((ele) => {
                if (e.cwrFbTableId === ele.fbTableId) {
                  if (ele.status === "OCU") {
                    e.color = "#bc4749";
                    e.noOfPersons = ele.guests;
                    e.guestName = ele.guestName;
                    e.guestType = ele.guestType;
                    e.referredBy = ele.referredBy;
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.statusType = "OCU";
                    e.waiter = ele.salesRepId;
                  } else if (ele.status === "RES") {
                    e.color = "#f2e8cf";
                    e.noOfPersons = ele.guests;
                    e.guestName = ele.guestName;
                    e.guestType = ele.guestType;
                    e.referredBy = ele.referredBy;
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.reservationTime = ele.reservationTime;
                    e.statusType = "RES";
                    e.waiter = ele.salesRepId;
                  } else if (ele.status === "OPN") {
                    e.color = "#a7c957";
                    e.noOfPersons = "";
                    e.guestName = "";
                    e.guestType = "";
                    e.referredBy = "";
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.statusType = "OPN";
                    delete e.cart;
                    delete e.fbOrder;
                    delete e.lines;
                    delete e.noOfPersons;
                    delete e.waiter;
                    delete e.salesRepId;
                    delete e.fbOrderId;
                    delete e.guestName;
                    delete e.guests;
                    delete e.guestType;
                    delete e.referredBy;
                    delete e.fbOrderStatus;
                    delete e.id;
                    // delete e.fbtableStatusId;
                  }
                }
              });
            }
          });
          if (netWorkerror === false) {
            await db.tableData.bulkPut(tempData);
            setTableCards(tempData);
            setTableList(tempData);
            setMainTableData(tempData);
          } else {
            let data = await db.tableData.toArray();
            setTableCards(data);
            setTableList(data);
            setMainTableData(data);
          }
          setLoading({ status: false, message: "" });
        } else {
          restaurantTablesSuccess();
        }
      } catch (error) {
        restaurantTablesFailure(error);
      }
    });
  };
  // CLOCK BLOCK END

  const handleKeyvalue = (value) => {
    setKeyValue(value);
    if (value === "2") {
      history.push("/pos");
      setKeyValue("2");
    }
  };

  const currentDate = currentDay().charAt(0).toUpperCase() + currentDay().slice(1);
  const currentDateValue = currentDate.substring(0, 3);

  const statusCards = [
    {
      title: "All",
      flag: "N",
      color: "#fff",
    },
    {
      title: "Reserved",
      status: <Badge color="#f2e8cf" text="Reserved" />,
      color: "#f2e8cf",
      flag: reserveFlag === "N" ? "N" : "Y",
    },
    {
      title: "Occupied",
      status: <Badge color="#bc4749" text="Occupied" />,
      color: "#bc4749",
      flag: "Y",
    },
    {
      title: "Open",
      color: "#a7c957",
      status: <Badge color="#a7c957" text="Open" />,
      flag: "Y",
    },
    {
      title: "Waiting",
      status: <Badge color="#FFA85B" text="Waiting" />,
      color: "#FFA85B",
      flag: "N",
    },
    {
      title: "No Service",
      status: <Badge color="#000000" text="No Service" />,
      color: "#000000",
      flag: "N",
    },
  ];

  const [displayMergeTablesPopup, setDisplayMergeTablesPopup] = useState(false);
  const [mergeTablesSelection, setMergeTablesSelection] = useState(false);

  const handleMergeTables = () => {
    setMergeTablesSelection(true);
    // setDisplayMergeTablesPopup(true);
  };

  const handleTable = async (record) => {
    setLoading({ status: true, message: "" });
    setRecardState(record);
    let array = [];
    await db.tableData
      .where("cwrFbTableId")
      .equals(record.cwrFbTableId)
      .toArray()
      .then(async (response) => {
        if (response.length > 0) {
          let orderedData = [];
          let cancelData = [];
          let cancelItems = [];
          let table = [...response];
          table[0]?.cart?.items.map((x) => {
            if (x.update === "N") {
              orderedData.push(x);
            }
            if (x.isCanceled === "Y") {
              cancelData.push(x);
              cancelItems.push(`{
                    fbOrderLineId:"${x.fbOrderLineId}"
                      isCanceled: "Y"
                  }`);
            }
          });

          if (cancelItems.length > 0) {
            let obj = {
              fbOrderId: table[0].fbOrderId || table[0].cart.fbOrderId,
              cancelItems: cancelItems,
            };
            SyncData(obj, "cancelItem");
          }
          array.push(...response);
          if (!response[0]?.noOfPersons) {
            if (inputRef.current) {
              inputRef.current.focus();
            }
            setLoading({ status: false, message: "" });
            setGuestsModalFlag(true);
          } else {
            record.noOfPersons = array[0].noOfPersons;
            record.waiter = array[0].waiter;
            record.guestName = array[0].guestName;
            record.guestType = array[0].guestType;
            record.fbOrderId = array[0].fbOrderId;
            record.fbtableStatusId = array[0].fbtableStatusId === undefined ? uuidv4().replace(/-/g, "").toUpperCase() : array[0].fbtableStatusId;
            // localStorage.removeItem("tableName");
            localStorage.setItem("tableName", JSON.stringify(record));
            // history.push({
            //   pathname: "/pos",
            // });
            setSelectedTable(record);
            setKeyValue("2");
          }
        }
      });
  };

  const handleOrderData = () => {
    const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
    localStorage.removeItem("tableName");
    localStorage.setItem(
      "tableName",
      JSON.stringify({
        table: "TakeAway" || "",
        id: uniqueId || "",
        noOfPersons: "",
        sectionName: "",
      })
    );
    // history.push({
    //   pathname: "/pos",
    //   state: {
    //     table: "TakeAway" || "",
    //     id: uniqueId || "",
    //     noOfPersons: "",
    //     sectionName: "",
    //   },
    // });
    setKeyValue("2");
  };

  const handleSelect = (key) => {
    let tempData = [];
    if (key === "all") {
      tempData.push(...mainTableData);
      setRadioValue({ value: "all", name: "All" });
    } else {
      mainTableData.map((e) => {
        if (e.cwrFbsectionId === key) {
          tempData.push(e);
          setRadioValue({
            value: key,
            name: e.sectionName,
            noOfPersons: recordState?.noOfPersons || "",
          });
        }
      });
    }
    setTableList(tempData);
    setTableCards(tempData);
    setRadioFlag(false);
  };

  const handleOnChange = (key) => {
    let tempData = [];
    if (key === "all") {
      tempData.push(...mainTableData);
    } else {
      mainTableData.map((e) => {
        if (e.cwrFbsectionId === key) {
          tempData.push(e);
        }
      });
    }
    setTableList(tempData);
  };

  const getCustomerData = async (values) => {
    setGuestsModalFlag(false);
    localStorage.setItem("dineIn", "Y");
    setLoading({ status: true, message: "" });
    let tabledata = [];
    await db.tableData.toArray().then((fetched) => {
      fetched.map((item) => {
        if (item.cwrFbTableId === recordState.cwrFbTableId) {
          item.noOfPersons = values.noOfGuests;
          item.waiter = values.waiter;
          item.referredBy = values.referredBy === undefined ? "" : values.referredBy;
          item.guestName = values.guestName === undefined ? "" : values.guestName;
          item.tableSync = "N";
          item.statusType = "OCU";
          item.fbtableStatusId = item.fbtableStatusId === undefined ? uuidv4().replace(/-/g, "").toUpperCase() : item.fbtableStatusId;
          item.guestType = priorityBtn ? "single" : "family";
          item.salesRepId = tillData.tillAccess.salesRepId;
          item.uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
          item.color = "#bc4749";
          item.ordered = "N";
        }
        tabledata.push(item);
      });
    });
    await db.tableData.clear();
    await db.tableData.bulkAdd(tabledata);
    localStorage.setItem("locatedList", JSON.stringify(tabledata));
    localStorage.setItem("tableName", JSON.stringify(recordState));
    setLoading({ status: false, message: "" });
    setSelectedTable(recordState);
    // history.push({ pathname: "/pos" });
    setKeyValue("2");
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    setInputFields({
      ...inputFields,
      [inputName]: inputVal,
    });
  };

  const handleKeyboardInputs = (button) => {
    if (button === "{shift}" || button === "{default}") setLayout(layout === "default" ? "shift" : "default");
    if (button === "{caps}") setLayout("shift");
    if (button === "{small}") setLayout("default");
    if (button === "{numbers}") setLayout("numbers");
    if (button === "{number}") setLayout("number");
  };

  const onChange = (inputs) => {
    setInputFields({ ...inputs });
    guestForm.setFieldsValue(inputs);
  };

  const onChangeCompany = (inputs) => {
    setInputFields({ ...inputs });
    setCompanySeacrhInput(inputs.customerSearchInput);
    getCompanyData(inputs.customerSearchInput);
  };

  const [form] = Form.useForm();
  const [displayReserveTablesPopup, setDisplayReserveTablesPopup] = useState(false);

  const handleReserveTable = () => {
    let newTime = moment(new Date());
    form.setFieldsValue({ section: radioValue.value, time: newTime });
    setDisplayReserveTablesPopup(true);
  };

  const handleReserveTableDetails = async (values) => {
    let value = values.tableNum.split("-");
    await db.tableData
      .where("cwrFbTableId")
      .equals(value[0])
      .toArray()
      .then(async (response) => {
        if (response.length > 0) {
          const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
          response[0].color = "#f2e8cf";
          response[0].statusType = "RES";
          response[0].noOfPersons = values.noOfPersons;
          response[0].referredBy = values.referredBy;
          response[0].uniqueId = uniqueId;
          response[0].guestType = priorityBtn ? "single" : "family";
          response[0].waiter = tillData.tillAccess.salesRepId;
          response[0].reservedTime = moment(values.time).format(userPreferences.dateTimeFormat);
          response[0].fbtableStatusId = response[0].fbtableStatusId === null || response[0].fbtableStatusId === undefined ? uniqueId : response[0].fbtableStatusId;
          response[0].tableSync = "N";
          await db.tableData.update(response[0].cwrFbTableId, response[0]);
          form.resetFields();
          setKeyboardVisible(false);
          setReloadFlag(!reloadFlag);
          setTimeout(() => {
            setKeyboardVisible(true);
            setDisplayReserveTablesPopup(false);
          }, 0);
          SyncData(response[0], "reservation");
          SyncData(response[0], "upsertTableStatus");
          setUpdateTable(true);
        }
      });
  };

  const handleRedirectDash = () => {
    history.push("/dashboard");
  };

  const handleSelectedData = (item) => {
    let tempData = [];
    mainTableData.map((key) => {
      if (radioValue.value === "all") {
        if (key.color === item.color) {
          tempData.push(key);
        }
      } else {
        if (key.color === item.color && key.sectionName === radioValue.name) {
          tempData.push(key);
        }
      }
    });
    setTableCards(tempData);
  };

  const getCompanyData = (value) => {
    return new Promise(function (resolve) {
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query{
            customerSearch(name:"${value}"){
              cwrCustomerId 
              code   
              name
              email        
              mobileNo
              name
              pincode
              retlLoyaltyBalance
              b2cRegisteredstoreId
               iscredit
              loyaltyLevel{
                  cwrLoyaltyLevelId
                  name
                  accumulationRate
                  redemptionRate
              }
              sCustomer{
                sCustomerID
                customerCategory{
                  sCustomerCateforyId
                  value
                  name
                  description
                }
              }
              csBunit{
                csBunitId
                name
              }
              b2cCustomerSegment{
                name
                cwrB2CCustomerSegmentId
              }
            }
            
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      })
        .then((response) => {
          if (response.data.data.customerSearch !== null && response.data.data.customerSearch.length > 0) {
            const result = response.data.data.customerSearch;
            setCustomerSearchResults(result);
            resolve(result[0]);
          } else {
            console.warn("No customer found, try searching with different customer");
            resolve(null);
            setCustomerSearchResults([]);
          }
        })
        .catch(() => {
          resolve(null);
        });
    });
  };

  const openCompanyData = () => {
    setDisplayCompanySearch(true);
    companySearchInputRef?.current?.focus();
  };

  const selectCompanyData = (name) => {
    guestForm.setFieldsValue({ referredBy: name });
    form.setFieldsValue({ referredBy: name });
    setCompanyName(name);
    setDisplayCompanySearch(false);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  const handleCheckboxChange = (e, node) => {
    const isChecked = e.target.checked;
    let tabledata = JSON.parse(localStorage.getItem("locatedList"));
    tabledata.forEach((item) => {
      if (item.id === node.fbTableId) {
        if (isChecked) {
          // Add the node to layawayData
          let layaway = layawayCheck ? layawayCheck : [];
          item.layawayData = [...layaway, node];
          setLayawayCheck(item.layawayData);
        } else {
          // Remove the node from layawayData
          item.layawayData = item.layawayData.filter((ele) => ele.uniqId !== node.uniqId);
          setLayawayCheck(item.layawayData);
        }
      }
    });
    setTempLocatedList(tabledata);
  };

  const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"];

  const tableLayoutProps = {
    tableCards,
    handleTable,
    setFbOrderLayawayData,
    setLaywayModal,
    mainTableData,
    setTableCards,
    restaurantSections,
    handleTable,
    handleReserveTable,
    occupiedDataTablesData,
    reservedTablesData,
    userPreferences,
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#fff" }} spin />} spinning={false}>
      <div style={{ height: "100%", width: "100%", backgroundColor: "#F3F4F9" }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>{keyValue === "0" && <TableLayout {...tableLayoutProps} />}</div>
          {/* <div style={{ width: "100%" }}>{keyValue === "5" && <CashManagement}</div> */}
        </div>
      </div>

      {/* MERGE TABLES POPUP STARTS */}
      <Modal visible={displayMergeTablesPopup} closable={false} footer={null} bodyStyle={{ padding: 20 }} width="30%" style={{ top: 200 }}>
        <div>
          <Row style={{ paddingTop: 10 }}>
            <Col span={24}>
              <p style={data.restaurantPos.tableManagement.mergeTables.modalTitle}>Are you sure want to Merge the Tables?</p>
            </Col>
          </Row>
          <Row style={data.restaurantPos.tableManagement.mergeTables.yesCancelBtns}>
            <Button
              size="medium"
              className="customerDetailsSubmitBtnBtn"
              style={data.restaurantPos.tableManagement.mergeTables.mergeTablesSubmitBtn}
              htmlType="submit"
              onClick={() => setDisplayMergeTablesPopup(false)}
            >
              Yes
            </Button>
            <Button
              size="medium"
              className="customerDetailsCancelBtn"
              style={data.restaurantPos.tableManagement.mergeTables.mergeTablesCancelBtn}
              onClick={() => setDisplayMergeTablesPopup(false)}
            >
              No
            </Button>
          </Row>
        </div>
      </Modal>
      {/* MERGE TABLES POPUP ENDS */}

      {/* RESERVE TABLE MODAL BEGIN */}
      <Drawer visible={displayReserveTablesPopup} placement="bottom" closable={false} height="80%" className="custom-drawer" bodyStyle={{ backgroundColor: "#fff" }}>
        <Form key={"reserveTable"} form={form} name="reserveTable" onFinish={handleReserveTableDetails}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2vh",
              fontSize: "1vw",
              marginTop: "1vh",
              paddingBottom: "5vh",
            }}
          >
            <p
              style={{
                fontSize: "2em",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                marginTop: "5vh",
              }}
            >
              Reserve Table
            </p>
            <img
              src={closeX}
              onClick={handleClick}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p style={{ color: "#0F0718", fontSize: "0.9em", fontWeight: 500 }}>No Of Persons</p>
              <div className="number-buttons-container">
                {numbers.map((number, index) => (
                  <div
                    key={index}
                    className="number-button"
                    style={{ backgroundColor: noOfPersons === number ? "#2F3856" : "#ffff", color: noOfPersons === number ? "#fff" : "#0f0718" }}
                    onClick={() => handleNumberClick(number)}
                  >
                    {number}
                  </div>
                ))}
              </div>
              {/* <Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
                <Col span={24} style={{ color: "#0F0718" }}>
                  Number of persons
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">1</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">2</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">3</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">4</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">5</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">6</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">7</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">8</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">9</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">10+</button>
                </Col>
              </Row> */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Name</label>
                  <Form.Item name="name" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Input
                      // readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("name");
                        setLayout("default");
                      }}
                      placeholder="Enter Name"
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Contact Number</label>
                  <Form.Item name="mobile" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Input
                      // readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("mobile");
                        setLayout("number");
                      }}
                      placeholder="Enter Contact Number"
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Time</label>
                  <Form.Item name="time" valuePropName="defaultValue" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <DatePicker
                      className="timePicker"
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      placeholder="Select Date"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "-9px",
                        paddingRight: "3px",
                      }}
                    >
                      *
                    </span>
                    No. of persons
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Enter No of Persons",
                      },
                    ]}
                    name="noOfPersons"
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Input
                      // readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("noOfPersons");
                        setLayout("num");
                      }}
                      placeholder="Enter No of Persons"
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Section</label>
                  <Form.Item name="section" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Select
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                      showSearch
                      placeholder="Section"
                      className="custom-select"
                      optionFilterProp="children"
                      readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("section");
                        setLayout("default");
                      }}
                      value={radioValue.value}
                      disabled={radioValue.value !== "all" ? true : false}
                      onChange={handleOnChange}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {selectData.map((item) => (
                        <Option value={item.value}> {item.name} </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Table Number</label>
                  <Form.Item
                    name="tableNum"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Table",
                      },
                    ]}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Select
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                      showSearch
                      placeholder="Select Table"
                      className="custom-select"
                      optionFilterProp="children"
                      readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("tableNum");
                        setLayout("default");
                      }}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {tableList.map((tableObj) => (
                        <Option
                          key={`${tableObj.cwrFbTableId}-${tableObj.cwrFbsectionId}`}
                          disabled={tableObj.color === "#a7c957" ? false : true}
                          value={`${tableObj.cwrFbTableId}-${tableObj.cwrFbsectionId}`}
                        >
                          {tableObj.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="referredBy" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Row gutter={[16, 16]}>
                      <Col span={11}>
                        <a onClick={openCompanyData} style={{ lineHeight: "1" }}>
                          Select Company
                        </a>
                      </Col>
                      <Col span={2} />
                      <Col
                        span={11}
                        style={{
                          textAlign: "center",
                          alignSelf: "center",
                          lineHeight: "1",
                        }}
                      >
                        <span>{companyName}</span>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ paddingTop: "10vh" }}>
              {keyboardVisible && (
                <Keyboard
                  buttonTheme={[
                    {
                      class: "hg-bksp",
                      buttons: "{bksp} {backspace}",
                    },
                    {
                      class: "hg-space",
                      buttons: "{space}",
                    },
                  ]}
                  keyboardRef={(r) => (keyboardRef.current = r)}
                  inputName={inputName}
                  onChangeAll={(inputs) => {
                    setInputFields({ ...inputs });
                    form.setFieldsValue(inputs);
                  }}
                  onKeyPress={handleKeyboardInputs}
                  layoutName={layout}
                  theme={"hg-theme-default signUp"}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{numbers}": "123",
                    "{number}": "123",
                    "{shift}": "⇧",
                    "{tab}": "tab",
                    "{lock}": "lock",
                    "{enter}": "enter",
                    "{space}": "Space",
                    "{caps}": "ABC",
                    "{small}": "abc",
                  }}
                  layout={{
                    shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
                    numbers: ["1 2 3", "4 5 6", "7 8 9", "{caps} 0 {bksp}"],
                    number: ["1 2 3", "4 5 6", "7 8 9", "{small} 0 {bksp}"],
                    default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
                    num: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
                  }}
                />
              )}
              <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                >
                  Reserve
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  onClick={() => {
                    setInputFields("");
                    setKeyboardVisible(false);
                    setTimeout(() => {
                      setKeyboardVisible(true);
                      setDisplayReserveTablesPopup(false);
                    }, 0);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Drawer>

      {/* <Drawer
        visible={displayReserveTablesPopup}
        placement="bottom"
        closable={false}
        height="80%"
        className="custom-drawer"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw", marginTop: "1vh" }}>
            <p style={{ fontSize: "2em", fontWeight: "500", color: "#0F0718", flex: 1, marginTop: "5vh" }}>Reserve Table</p>
            <img src={closeX} onClick={handleClick} style={{ marginLeft: "auto", paddingBottom: "4vh", paddingTop: "2vh", cursor: "pointer", width: "1.5vw" }} />
          </div>
        }
      >
        <Form key={"reserveTable"} form={form} name="reserveTable" onFinish={handleReserveTableDetails}>
          <Row gutter={[16, 16]}>
            <Col span={13}>
              <Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
                <Col span={24} style={{ color: "#0F0718" }}>
                  Number of persons
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">1</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">2</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">3</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">4</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">5</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">6</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">7</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">8</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">9</button>
                </Col>
                <Col span={2}>
                  <button className="no-persons-btns">10+</button>
                </Col>
              </Row>

              <Row gutter={[0, 16]} style={{ paddingTop: "2vh" }}>
                <Col span={12} style={{ paddingRight: "16px" }}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Waiter</label>
                  <Form.Item
                    name="waiter"
                    rules={[{ required: true, message: "select waiter" }]}
                    initialValue={tillData.tillAccess.salesRepId}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    {tillData.tillAccess.salesRep !== "N" ? (
                      <Select className="custom-select">
                        {tillData.tillAccess.csBunit.salesRep.map((item) => (
                          <Option key={item.salesRepresentId} value={item.salesRepresentId}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select disabled className="custom-select">
                        <Option key={tillData.tillAccess.salesRepId} value={tillData.tillAccess.salesRepId}>
                          {localStorage.getItem("username")}
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>
                    <span style={{ color: "red", marginLeft: "-9px", paddingRight: "3px" }}>*</span>No Of Guests
                  </label>
                  <Form.Item
                    name="noOfGuests"
                    rules={[{ required: true, message: "Please enter No Of Guests" }]}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Input
                      placeholder="No Of Guests"
                      readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("noOfGuests");
                        setLayout("num");
                      }}
                      onChange={onChangeInput}
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingRight: "16px" }}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Guest Name</label>
                  <Form.Item
                    name="guestName"
                    onFocus={() => {
                      setInputName("guestName");
                      setLayout("default");
                    }}
                    onChange={onChangeInput}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Input readOnly ref={inputRef} placeholder="Guest Name" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Table Number</label>
                  <Form.Item style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Select className="custom-select"></Select>
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingRight: "16px" }}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Time</label>
                  <Form.Item name="Time" style={{ border: "0", marginBottom: 0 }}>
                    <TimePicker placeholder="HH:mm" format="HH:mm" style={{ width: "100%", height: "6vh", border: "none" }} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Date</label>
                  <Form.Item name="Date" style={{ border: "0" }}>
                    <DatePicker placeholder="DD/MM/YYYY" format="DD/MM/YYYY" style={{ width: "100%", height: "6vh", border: "none" }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[0, 16]} style={{ paddingTop: "2vh" }}>
                <Col span={24} style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>
                  Add tag
                </Col>
                <Col span={6}>
                  <Radio style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Anniversary</Radio>
                </Col>
                <Col span={6}>
                  <Radio style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Birthday</Radio>
                </Col>
                <Col span={6}>
                  <Radio style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Special occasion</Radio>
                </Col>
                <Col span={6}>
                  <Radio style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Other</Radio>
                </Col>
              </Row>
            </Col>

            <Col span={1} />
            <Col span={10} style={{ marginTop: "1%" }}>
              {keyboardVisible && (
                <Keyboard
                  buttonTheme={[
                    { class: "hg-bksp", buttons: "{bksp} {backspace}" },
                    { class: "hg-space", buttons: "{space}" },
                  ]}
                  keyboardRef={(r) => (keyboardRef.current = r)}
                  inputName={inputName}
                  onChangeAll={onChange}
                  onKeyPress={handleKeyboardInputs}
                  layoutName={layout}
                  theme={"hg-theme-default signUp"}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{numbers}": "123",
                    "{number}": "123",
                    "{shift}": "⇧",
                    "{tab}": "tab",
                    "{lock}": "lock",
                    "{enter}": "enter",
                    "{space}": "Space",
                    "{caps}": "ABC",
                    "{small}": "abc",
                  }}
                  layout={{
                    shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
                    numbers: ["1 2 3", "4 5 6", "7 8 9", "{caps} 0 {bksp}"],
                    number: ["1 2 3", "4 5 6", "7 8 9", "{small} 0 {bksp}"],
                    default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
                    num: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
                  }}
                />
              )}
              <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                >
                  Okay
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  // onClick={() => {
                  //   setInputFields("");
                  //   setKeyboardVisible(false);
                  //   guestForm.resetFields();
                  //   setGuestsModalFlag(false);
                  // }}
                  onClick={handleClick}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Drawer> */}

      {/* RESERVE TABLE MODAL END */}

      {/* No Of Guests Confirmation Modal Start */}
      <Drawer visible={guestsModalFlag} placement="bottom" closable={false} height="80%" className="custom-drawer" bodyStyle={{ backgroundColor: "#fff" }}>
        <Row style={{ paddingLeft: "1.2vw", fontSize: "1vw" }} className="test">
          <Col span={16}>
            <p style={{ fontSize: "1.5em", color: "#0f0718", fontWeight: "500" }}>Enter Number Guests</p>
          </Col>
        </Row>
        <Form form={guestForm} name="guestForm" onFinish={getCustomerData}>
          <Row>
            <Col span={12} style={{ padding: "0 1vw", fontSize: "1vw" }}>
              <Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
                <Col span={11}>
                  <Button
                    className="section-btn"
                    onClick={() => setPriorityBtn(false)}
                    style={{
                      backgroundColor: priorityBtn ? "#fff" : "#2F3856",
                      color: priorityBtn ? "#2F3856" : "#fff",
                      boxShadow: "none",
                      border: "1px solid rgba(146, 144, 152, 0.5)",
                      borderRadius: "5px",
                      fontSize: "1.2em",
                      fontWeight: "500",
                    }}
                  >
                    Family
                  </Button>
                </Col>
                <Col span={2} />
                <Col span={11}>
                  <Button
                    className="section-btn"
                    onClick={() => setPriorityBtn(true)}
                    style={{
                      backgroundColor: !priorityBtn ? "#fff" : "#2F3856",
                      color: !priorityBtn ? "#2F3856" : "#fff",
                      boxShadow: "none",
                      border: "1px solid rgba(146, 144, 152, 0.5)",
                      borderRadius: "5px",
                      fontSize: "1.2em",
                      fontWeight: "500",
                    }}
                  >
                    Others
                  </Button>
                </Col>
              </Row>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Waiter</label>
                  <Form.Item
                    name="waiter"
                    rules={[
                      {
                        required: true,
                        message: "select waiter",
                      },
                    ]}
                    initialValue={tillData.tillAccess.salesRepId}
                    style={{
                      ...data.restaurantPos.tableManagement.reserveTableModal.formItem,
                      border: "1px solid #d9d9d9",
                      borderRadius: "3px",
                      height: "7vh",
                    }}
                  >
                    {tillData.tillAccess.salesRep !== "N" ? (
                      <Select className="custom-select">
                        {tillData.tillAccess.csBunit.salesRep.map((item, index) => (
                          <Option key={item.salesRepresentId} value={item.salesRepresentId}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select disabled className="custom-select">
                        <Option key={tillData.tillAccess.salesRepId} value={tillData.tillAccess.salesRepId}>
                          {localStorage.getItem("username")}
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "-9px",
                        paddingRight: "3px",
                      }}
                    >
                      *
                    </span>
                    No Of Guests
                  </label>
                  <Form.Item
                    name="noOfGuests"
                    rules={[
                      {
                        required: true,
                        message: "Please enter No Of Guests",
                      },
                    ]}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Input
                      placeholder="No Of Guests"
                      className="restaurantInput"
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("noOfGuests");
                        setLayout("num");
                      }}
                      onChange={onChangeInput}
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                    />
                  </Form.Item>

                  {/* <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Name</label>
                  <Form.Item name="name" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Input
                      placeholder="No Of Guests"
                      readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("noOfGuests");
                        setLayout("num");
                      }}
                      onChange={onChangeInput}
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                    />
                  </Form.Item> */}
                </Col>
                <Col span={24}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Guest Name</label>
                  <Form.Item
                    name="guestName"
                    onFocus={() => {
                      setInputName("guestName");
                      setLayout("default");
                    }}
                    onChange={onChangeInput}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter guest name",
                    //   },
                    // ]}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Input ref={inputRef} className="restaurantInput" placeholder="Guest Name" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {/* <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Referred By</label> */}
                  <Form.Item name="referredBy" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Row gutter={[16, 16]}>
                      <Col span={11}>
                        <a onClick={openCompanyData}>Select Company</a>
                      </Col>
                      <Col span={2} />
                      <Col span={11} style={{ textAlign: "center", alignSelf: "center" }}>
                        <span>{companyName}</span>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ marginTop: "1%", padding: "0 1vw" }}>
              {keyboardVisible && (
                <Keyboard
                  buttonTheme={[
                    {
                      class: "hg-bksp",
                      buttons: "{bksp} {backspace}",
                    },
                    {
                      class: "hg-space",
                      buttons: "{space}",
                    },
                  ]}
                  keyboardRef={(r) => (keyboardRef.current = r)}
                  inputName={inputName}
                  onChangeAll={onChange}
                  onKeyPress={handleKeyboardInputs}
                  layoutName={layout}
                  theme={"hg-theme-default signUp"}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{numbers}": "123",
                    "{number}": "123",
                    "{shift}": "⇧",
                    "{tab}": "tab",
                    "{lock}": "lock",
                    "{enter}": "enter",
                    "{space}": "Space",
                    "{caps}": "ABC",
                    "{small}": "abc",
                  }}
                  layout={{
                    shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
                    numbers: ["1 2 3", "4 5 6", "7 8 9", "{caps} 0 {bksp}"],
                    number: ["1 2 3", "4 5 6", "7 8 9", "{small} 0 {bksp}"],
                    default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
                    num: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
                  }}
                />
              )}
              <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                  // onClick={() => setDisplayReserveTablesPopup(false)}
                >
                  Okay
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  onClick={() => {
                    setInputFields("");
                    setKeyboardVisible(false);
                    guestForm.resetFields();
                    setTimeout(() => {
                      setKeyboardVisible(true);
                      setGuestsModalFlag(false);
                    }, 0);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Drawer>

      {/* <Modal visible={guestsModalFlag} centered closable={false} footer={null} bodyStyle={{ padding: "20px" }} width="70%">
        <Row style={{ marginBottom: 10 }} className="test">
          <Col span={16}>
            <p className="enter-receipt">Enter Number Guests</p>
          </Col>
        </Row>
        <Form form={guestForm} name="guestForm" onFinish={getCustomerData}>
          <Row>
            <Col span={9}>
              <Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
                <Col span={11}>
                  <Button
                    className="section-btn"
                    onClick={() => setPriorityBtn(false)}
                    style={{
                      backgroundColor: priorityBtn ? "#fff" : "rgb(81, 173, 154)",
                      color: priorityBtn ? "#000" : "#fff",
                    }}
                  >
                    Family
                  </Button>
                </Col>
                <Col span={2} />
                <Col span={11}>
                  <Button
                    className="section-btn"
                    onClick={() => setPriorityBtn(true)}
                    style={{
                      backgroundColor: !priorityBtn ? "#fff" : "rgb(81, 173, 154)",
                      color: !priorityBtn ? "#000" : "#fff",
                    }}
                  >
                    Others
                  </Button>
                </Col>
              </Row>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Waiter</label>
                  <Form.Item
                    name="waiter"
                    rules={[
                      {
                        required: true,
                        message: "select waiter",
                      },
                    ]}
                    initialValue={tillData.tillAccess.salesRepId}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    {tillData.tillAccess.salesRep !== "N" ? (
                      <Select>
                        {tillData.tillAccess.csBunit.salesRep.map((item, index) => (
                          <Option key={item.salesRepresentId} value={item.salesRepresentId}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select disabled>
                        <Option key={tillData.tillAccess.salesRepId} value={tillData.tillAccess.salesRepId}>
                          {localStorage.getItem("username")}
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "-9px",
                        paddingRight: "3px",
                      }}
                    >
                      *
                    </span>
                    No Of Guests
                  </label>
                  <Form.Item
                    name="noOfGuests"
                    rules={[
                      {
                        required: true,
                        message: "Please enter No Of Guests",
                      },
                    ]}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Input
                      placeholder="No Of Guests"
                      readOnly
                      ref={inputRef}
                      onFocus={() => {
                        setInputName("noOfGuests");
                        setLayout("num");
                      }}
                      onChange={onChangeInput}
                      style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Guest Name</label>
                  <Form.Item
                    name="guestName"
                    onFocus={() => {
                      setInputName("guestName");
                      setLayout("default");
                    }}
                    onChange={onChangeInput}
                    style={data.restaurantPos.tableManagement.reserveTableModal.formItem}
                  >
                    <Input readOnly ref={inputRef} placeholder="Guest Name" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="referredBy" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                    <Row gutter={[16, 16]}>
                      <Col span={11}>
                        <a onClick={openCompanyData}>Select Company</a>
                      </Col>
                      <Col span={2} />
                      <Col span={11} style={{ textAlign: "center", alignSelf: "center" }}>
                        <span>{companyName}</span>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={14} style={{ marginTop: "1%" }}>
              {keyboardVisible && (
                <Keyboard
                  buttonTheme={[
                    {
                      class: "hg-bksp",
                      buttons: "{bksp} {backspace}",
                    },
                    {
                      class: "hg-space",
                      buttons: "{space}",
                    },
                  ]}
                  keyboardRef={(r) => (keyboardRef.current = r)}
                  inputName={inputName}
                  onChangeAll={onChange}
                  onKeyPress={handleKeyboardInputs}
                  layoutName={layout}
                  theme={"hg-theme-default signUp"}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{numbers}": "123",
                    "{number}": "123",
                    "{shift}": "⇧",
                    "{tab}": "tab",
                    "{lock}": "lock",
                    "{enter}": "enter",
                    "{space}": "Space",
                    "{caps}": "ABC",
                    "{small}": "abc",
                  }}
                  layout={{
                    shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
                    numbers: ["1 2 3", "4 5 6", "7 8 9", "{caps} 0 {bksp}"],
                    number: ["1 2 3", "4 5 6", "7 8 9", "{small} 0 {bksp}"],
                    default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
                    num: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
                  }}
                />
              )}
              <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                  // onClick={() => setDisplayReserveTablesPopup(false)}
                >
                  Okay
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  onClick={() => {
                    setInputFields("");
                    setKeyboardVisible(false);
                    guestForm.resetFields();
                    setTimeout(() => {
                      setKeyboardVisible(true);
                      setGuestsModalFlag(false);
                    }, 0);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal> */}
      {/* No Of Guests Confirmation Modal End */}

      {/* Radio Modal Start */}
      <Modal
        visible={radioFlag}
        title={"Section Table"}
        closable={true}
        footer={null}
        onCancel={() => {
          setRadioFlag(false);
        }}
        bodyStyle={{ padding: "15px" }}
        width="55%"
      >
        <Row gutter={[16, 16]}>
          {selectData.map((item) => (
            <Col span={6}>
              <Button className="section-btn" onClick={() => handleSelect(item.value)}>
                {item.name}
              </Button>
            </Col>
          ))}
        </Row>
      </Modal>
      {/* Radio Modal End */}

      {/* Company Modal Start */}
      <Modal
        title={
          <div>
            <Row>
              <Col span={24}>
                <p style={data.restaurantPos.tableManagement.reserveTableModal.modalTitle}>Search Company</p>
              </Col>
            </Row>
          </div>
        }
        visible={displayCompanySearch}
        closable={true}
        footer={null}
        width="70%"
        onCancel={() => setDisplayCompanySearch(false)}
        keyboard={false}
        bodyStyle={{ padding: 15 }}
      >
        <Row>
          <Col span={9}>
            <Input
              ref={companySearchInputRef}
              placeholder="Search"
              size="large"
              readOnly
              // style={data.posScreenStyles.customerModal.customerSearchInput}
              className="customerSearchInput"
              suffix={<SearchOutlined onClick={getCompanyData} />}
              onFocus={() => {
                setCompanyInputName("customerSearchInput");
                setLayout("default");
              }}
              value={companySearchInput}
              onPressEnter={getCompanyData}
              onChange={(e) => {
                onChangeCompany;
              }}
            />
            <Row style={{ paddingTop: 10 }}>
              <Col span={24}>
                {
                  <div style={{ textAlign: "left" }}>
                    <Card
                      bordered
                      bodyStyle={{ padding: 10 }}
                      style={{
                        maxHeight: "60vh",
                        minHeight: "55vh",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "5px",
                      }}
                    >
                      <Scrollbars autoHide className="customerCardScroll">
                        {customerSearchResults.map((customerSearchResult, index) => (
                          <Card
                            tabIndex={0}
                            // onKeyPress={(e) => (e.charCode === 13 ? selectCustomer(index) : null)}
                            onClick={() => {
                              selectCompanyData(customerSearchResult.name);
                              localStorage.setItem("customerName", JSON.stringify(customerSearchResult));
                            }}
                            key={`${index}`}
                            bodyStyle={{ padding: 10 }}
                            style={{
                              background: "#0C173A0d",
                              marginBottom: "10px",
                            }}
                          >
                            <Row>
                              <Col span={24}>
                                <p>{customerSearchResult.name}</p>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </Scrollbars>
                    </Card>
                  </div>
                }
              </Col>
            </Row>
          </Col>
          <Col span={1} />
          <Col
            span={14}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {keyboardVisible && (
              <Keyboard
                buttonTheme={[
                  {
                    class: "hg-bksp",
                    buttons: "{bksp} {backspace}",
                  },
                  {
                    class: "hg-space",
                    buttons: "{space}",
                  },
                ]}
                keyboardRef={(r) => (keyboardRef.current = r)}
                inputName={companyInputName}
                onChangeAll={onChangeCompany}
                onKeyPress={handleKeyboardInputs}
                layoutName={layout}
                theme={"hg-theme-default signUp"}
                display={{
                  "{abc}": "ABC",
                  "{smileys}": "\uD83D\uDE03",
                  "{numbers}": "123",
                  "{number}": "123",
                  "{shift}": "⇧",
                  "{tab}": "tab",
                  "{lock}": "lock",
                  "{enter}": "enter",
                  "{space}": "Space",
                  "{caps}": "ABC",
                  "{small}": "abc",
                }}
                layout={{
                  shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
                  numbers: ["1 2 3", "4 5 6", "7 8 9", "{caps} 0 {bksp}"],
                  number: ["1 2 3", "4 5 6", "7 8 9", "{small} 0 {bksp}"],
                  default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
                  num: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
                }}
              />
            )}
            {/* <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                  // onClick={() => setDisplayReserveTablesPopup(false)}  
                >
                  Okay
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  onClick={() => {
                    setInputFields("");
                    setKeyboardVisible(false);
                    guestForm.resetFields();    
                    setTimeout(() => {
                      setKeyboardVisible(true);
                      setGuestsModalFlag(false);
                    }, 0);
                  }}
                >
                  Cancel
                </Button>
              </Row> */}
          </Col>
        </Row>
      </Modal>
      {/* Company Modal End */}

      {/* Layway Modal Start */}
      <Modal
        visible={laywayModal}
        title={"Layaway Table"}
        closable={true}
        footer={
          <div style={{ display: "flex" }}>
            <Button
              type="primary"
              onClick={() => {
                if (layawayCheck.length > 0) {
                  localStorage.setItem("locatedList", JSON.stringify(tempLocatedList));
                  localStorage.setItem(
                    "layaway",
                    JSON.stringify({
                      table: fbOrderLayawayData[0].fbTableId,
                      value: "true",
                    })
                  );
                  // history.push("/pos");
                  setKeyValue("2");
                }
              }}
              style={{ marginRight: "1vw" }}
            >
              Pay
            </Button>
            <Button
              onClick={() => {
                setLayawayCheck([]);
                setLaywayModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        }
        onCancel={() => {
          setLayawayCheck([]);
          setLaywayModal(false);
        }}
        bodyStyle={{ padding: "15px" }}
        width="35%"
      >
        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0 3vw",
          }}
        >
          {fbOrderLayawayData.map((node, index) => (
            <div key={`Order${index + 1}`}>
              <Checkbox onChange={(e) => handleCheckboxChange(e, node)}>
                <span style={{ width: "26vw" }}>{`Order - ${index + 1}`}</span> <span style={{ marginLeft: "12vw" }}>{`Price - ${node.total}`}</span>
              </Checkbox>
              <ul>
                {node.lines.map((childNode, ind) => (
                  <li key={ind}>{JSON.parse(childNode.meta[0].value).name}</li>
                ))}
              </ul>
            </div>
          ))}
        </Row>
      </Modal>
      {/* Layway Modal End */}
    </Spin>
  );
};
export default TableManagement;
