import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Table, Input, Rate } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./mobile.css";
import "react-simple-keyboard/build/css/index.css";
import Scan from "../../../../assets/images/scan.gif";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import SMS from "../../../../assets/images/sms-solid.svg";
import Receipt from "../../../../assets/images/receipt.svg";
import CrediCard from "../../../../assets/images/credit-card.svg";
import AddBag from "../../../../assets/images/bag-outline.svg";
import QRCode from "../../../../assets/images/qr-code.svg";
import GiftCard from "../../../../assets/images/gift-card.svg";
import Loyality from "../../../../assets/images/loyalty.svg";
import Sucess from "../../../../assets/images/sucess.gif";
import Settings from "../../../../assets/images/settings.gif";
import Sync from "../../../../assets/images/dataSync.gif";
import Cashup from "../../../../assets/images/cashup.gif";
import kioskBGI from "../../../../assets/images/kioskBGI.png";
import cashIcon from "../../../../assets/images/cashIcon.svg";
import Qrcode from "../../../../assets/images/qrcode.svg";

import _ from "lodash";

import { PlusOutlined, MinusOutlined, CloseSquareFilled } from "@ant-design/icons";

const PaymentSelection = (props) => {
  const {
    addProduct,
    cart,
    checkIsManualWeight,
    clearProductSearchResults,
    decreaseProductQty,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    openPaymentModal,
    parkBill,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    tillData,
    clearSelectedProductInCart,
    productListCardRef,
    removeCutomer,
    selectedProductCategory,
    handleHorizantalScroll,
    handleScroll,
    handleKeyvalue,
    selectdata,
    removeProductModal,
    ref,
    productItems,
    setProductItems,
    productArray,
    setProductArray,
    setIsProductsVisible,
    requestPayment,
    selectedPaymentMethod,
    processOrder,
    amount,
    setKioskUI,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    openPaymentModalByCustomer,
  } = props;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies;
  const customerId = tillData?.tillAccess?.csBunit.b2cCustomer.cwrCustomerId;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userName = userData?.user;
  const [layoutType, setLayoutType] = useState(0);
  const [rating, setRating] = useState(0);
  const [improvement, setImprovement] = useState("");
  const [recommendation, setRecommendation] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState({});
  const [feedbackJson, setFeedbackJson] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const [isLocked, setIsLocked] = useState(false);
  const mainDivStyles = themeJSON.appBgImageURL
    ? {
        backgroundImage: `url(${themeJSON.appBgImageURL})`,
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: isLocked ? "none" : "block",
      }
    : {
        backgroundImage: `url(${kioskBGI})`,
        backgroundSize: "cover",
        backgroundPosition: "10% 10%",
        // backgroundColor: colorVariable ? colorVariable : "#989898",
        height: "100vh",
        width: "100%",
        position: "absolute",
      };

  const logoStyles = isThemeLogo
    ? { height: "8vh", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer", width: "5vw" } // Styles for theme logo
    : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer" }; // Styles for kisok logo

  useEffect(() => {
    const storedFeedbackJson = localStorage.getItem("feedbackJson");
    if (storedFeedbackJson) {
      setFeedbackJson(JSON.parse(storedFeedbackJson));
    }
  }, []);

  const getFeedbackName = () => {
    if (feedbackJson && feedbackJson.enable_rating === "Y") {
      return feedbackJson.name || "How was your experience?";
    }
    return "How was your experience?";
  };

  const handleRatingChange = (value) => {
    setRecommendation(value);
  };

  const handleInputChange = (questionId, value) => {
    switch (questionId) {
      case "Recommendation":
        setRecommendation(value);
        break;
      case "SelectedFeatures":
        setSelectedFeatures(value);
        break;
      case "Improvement":
        setImprovement(value);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (questionId, value) => {
    setSelectedFeatures((prevFeatures) => ({
      ...prevFeatures,
      [questionId]: value, // Only one value per question
    }));
  };

  const handleChange = (value) => {
    setRating(value);
    // setLayoutType(4);
  };

  // useEffect(() => {
  //   if (rating > 0) {
  //     const timer = setTimeout(() => {
  //       setLayoutType(4);
  //     }, 1500);
  //     return () => clearTimeout(timer);
  //   }
  // }, [rating]);

  useEffect(() => {
    if (rating > 0) {
      const timer = setTimeout(() => {
        // setLayoutType(4);
        const feedbackJson = localStorage.getItem("feedbackJson");
        if (feedbackJson && JSON.parse(feedbackJson).length === 0) {
          localStorage.setItem("kioskUI", 0);
          setKioskUI(0);
          const customEvent = new CustomEvent("customStorageChange", {
            detail: { key: "kioskUI", newValue: 0 },
          });
          window.dispatchEvent(customEvent);
        } else {
          setLayoutType(4);
        }
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [rating]);

  const handleSubmit = () => {
    const feedbackData = {
      recommendation,
      bestFeature: selectedFeatures,
      improvement,
    };
    localStorage.setItem("kioskUI", 0);
    setKioskUI(0);
    const customEvent = new CustomEvent("customStorageChange", {
      detail: { key: "kioskUI", newValue: 0 },
    });
    window.dispatchEvent(customEvent);
  };

  const feedbackData = [
    {
      // gif: "https://s3-alpha-sig.figma.com/img/03a9/c8a4/e35687011ba2313ecaa09fc4b050f63c?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bvktaNAm71F9-YzhStkUWKQRdUIj~FDrbDQAXUxEqE2htrDC9O-viHwpdbNiuvxSxXjNU6joA7~39KeklLxBRlddBdhGA2BTq7hkc5yUFAS6d8l8TnrByVjATRq1gDMiqOUsj4F1zDOO2YnTBH14PVWG4RQbY9eV0TIIQPpUZUYTawVBSfBpYWXMRq7wtrUChCZFOZhCoVdeQoitXkTwnqT39iFOR4aVLqBu-xw43oLDyBSJfC69e2SsAlR9Xe5xJehN3pRsHfYAxBIloh64d6Dh9NGat0cyFLDe8GOUHEP0QK-aBd1uY9gZt6vwmbJEPbKTPzJF1Dz5jPBhKz1EAg__",
      gif: "https://s3-alpha-sig.figma.com/img/03a9/c8a4/e35687011ba2313ecaa09fc4b050f63c?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JVrVIA8EwhEZ8Idz~lttnzrh9KE3aIkBEuNipDjS4SjIIcuLSiQ553M~T0Q2mj4-3NZNIwdI6C3qoI7aVAd04ge17wz0Vw6UFqldyX38F-eBNDeaQKNJU28BfdQVMybyAY6jOQV2pQPo1~OaosjgshMFft8WZS-p4WoPKyRda5nd8dp6qjPN0xX23AOuabU6HlMHVLX70et3tRbm07gB45HnKYxJaZl7vp3sIT8ENz6eorQRksa3ts~hzgf04FYOxfgT8H~zgFOu1~KXrA72lrs7NjUlhUsM36cS6vHnsIFGtM4OqkwXDpSJ9h8ANe8GqbHypKKitBjb0FrySH-lPQ__",
      text: "Disappointing",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/b221/1cd2/15af41f80db44a56689429583b89c8ae?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FGsXieayqd~TCZ-QQqIYPv6JMu0O3VZT8uFeM5TOd9frV5DCMXWxwgUKES~ESZpq9qaaeUGTBlwhlpf3Q9yAdv3w1GK6kQAbZV-36tulE5785GR~QQtGvT2fpGoSUA0lqngL7vofbAVFWGcPKuKEmXzBH8lPgtz3rN1VCdH-CRViOqhZqO9OwH~QDsZbctraM-CWDzem-6nZs6ZHGrNwfAY9IJapDFib3o5qCL-kuHOzbvqmlZXfy-7yBqNmyW7gzaAAX7nbxAv309-m8icyqpi-UCv4fYwRBO~dVKGp~9uyuWoDTyp5JLeR0JNRINF82xH7XXM0jMBR4d2fY4gZCQ__",
      gif: "https://s3-alpha-sig.figma.com/img/b221/1cd2/15af41f80db44a56689429583b89c8ae?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Fu0nfoQ5yRfB2DYhPeO6m4E8ICpf4ITiwvmYMnK1AaCKe5FkqHGdtlbnJzSwDYP4oIyasFubfbbMN8CyypLBhdln7wensNSDMX-3J8wgFZAGPEmGWeWBmvae6trAPA1BgxWlqdcgm5KFvIVDh8p3E73Df8YqN83auwAuwmVXi-D9xFJHmhE2WP90S6zUtjNbFm63pD6XuPFotLOrmFLg2lRxfJa8w2RVrovXTzjs~p2cZTSjorampPYGlEaxgWVJG5MUjZObypKeKKC1e0f1CeVBwd3g42KbhKo0P5ft4Yt5W6pb3fEXVHVO3ynRp2WPm0fKrEDlUJrjro3HsY55-Q__",
      text: "Just OK",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dlTVj9cfxHwex3VGsJE4IC2fUfNyHN1YYlL6q0ObDSHxLZL1v2xw97mXkkSTCAcxJDQytT~VgKUuU5NR-qm6-V2KGrIMYtTFwiZoNV2sxNZ7nEzGRriWvdkk6k0quYd1WmTm7-Q0h8jfzTlf4IcW4K5ppsPoBfhRp5D5ZcYAsiY~b~3qB4P24oW0zSDa6o8uLsfoRUVt4fmdcx1I2hZWGAvOwJWi3IaEs9tCQ1mN-ta1denNEhu0F66~XgPGLJpTdUhrFB5LylBvJQoBBVP-zLlrPbzyEXOTlqztX-r3NGpk8ssuHn9O6cn8svExbcFBSfssteXgS~iZqq4ksSnK2g__",
      gif: "https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gij0mj8NCYJD5aWuJhhCntGGV8oLH-geWAkgnRF7zYrZiQAfTZwdV8-9thOHaBArjWHQUEeLk8sHW3TAQ4UINCrWYtoK4DmwvpaE4DksILxL~Ig8C09gdHmDywPFqlGG6g7YnpXusnkZh4a1MtHnn5DRqa~zFX3zDCiggokqm3oWW0MTTgDskByGL-lqqTUY~jbT0knR4mJnoOHzo86bpqoEsufyW1x-kvtsV3KzyNElk07G9djGtW1RlZyaonrYy3iyMAk090A4kJeR57rwEKR2Cs5kK4aPhEgLtpNlH03z5LMzQosGQHAVEgjTcu4-IHewDXULxe9CUlkOBnWDoQ__",
      text: "Satisfactory",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/0f17/b5a3/47afe79f315fc8178f9a03cf6b455405?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=M5Vj~Ik3SSYUDG~UhZIj8PtdCqmdSwashX-FgMxFpqBR7WXS2gyT5FtfRworuuRPPagWrbBB039xOozQA-hxdGpy1tPXGWrkWrhEzMhSJ7~I34vy8M8WxfyGloOZLiKQxc1XVjNCTibBzOgR~PgMgeiqy1ZO4aGLx9hitwCNsWK0EuoA-MwDSot4OxiTu8aNaxve4UpHut5oSLC01d2L6wQqAu56OSLHKztidAMiCauXw2gG7XIRpsLnsobv-c94J3rqzzBConw8qBbnpmSQ6nm9etTw~iSmocGnBrn6WzAI3t70bcNs9haS7Ov8FbdbDpcAELkOCZZbjfk5dfMCNA__",
      gif: "https://s3-alpha-sig.figma.com/img/0f17/b5a3/47afe79f315fc8178f9a03cf6b455405?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=k6ljjnHWeEzy1wXyGmr5dbReYe1z2ef1P6eRqnpv0pJb~y2W0PAFpCHcCJtpp6xUEix54EtLDCRQ3m6OgmisQIRSezX7Er0Jdqis1Vnnl9MAKKlxPDANi6PtVvdRYKyGTSDkD-gQ5RmtFf1gvlhhcoscrcPVKneYI23hbx90O6DIGhIZ2OIvLuDEl3GlyOWcrdSkWT4HvJG3iKJWMLrW8st9rd7lUZGE~o0YqVFFIgaXAj4xeula6IEFo9YCWAqMW~Pa3XaPc6PjtWN52G4PIMfb7p5QuVZsYTN2u~plTjgptk-~zGF82WSKnjlnrLtgTssc46lJWhvqZIaVpmA7FA__",
      text: "Impressive",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/0975/2a13/d8f893cb3f1cb5938e1253295f70e9e3?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QkLQmtPqIpmVjaL5fnVLejOhFAxqFwOPFm6vfLZt3Fl3LaCqRkNHoq-Nh53xm9zZdCnPax0Sd9PbfIiz6o8slvun~ehbPK1MNqrUQ52dPlGOTCeWOh15yoDma92Jro~3ZfrVAI5qtO4Q9~Ew4DjG~QNBIw5HNeaFdSMzkeMVL-s7cIWWtdqEqeRvwrOh4Qe5BWq3yrJQEG13l3pgTrQRcM5R5tX619Yz7N4PFooF9PErsyqvRuR~42yJAkagr77xjh-9bS5nFaEBBIxxBq88f6w6OYogNyjdyv5YSiagb9sUloDeGOpXHOz4DXqj0yFWXOHRh9QDjw0Xt4JJcrRp5w__",
      gif: "https://s3-alpha-sig.figma.com/img/0975/2a13/d8f893cb3f1cb5938e1253295f70e9e3?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DryEIPVlmjFyglyBLK3ry0blP0AEsQt9iElHCQce~gKau-a06L3Z5yr9YU1AUQoK1pofI0ARsD1A1uIDwBDpS2vwhPT1O08QqoxLfiDRRTPyCuQo73jilt9tuimnG8Vwv6y18urZ8F0ZzzO9u6SOgliF2F4UoRYz4hVeDli7MCwor7OGeJTPwVHNM1v-hnD42Pr05maiouxtg~pqFvAqnLfR0MGfpaB5qLmuKWJyzKTBhSvsZOQhxhclWmwif313Zg1Riez7m3e3wMU2ReNSBJvKG2GC5K3VW5Em~q99P7RaED7ABiDgB6tBh34UMA207feTRWEOtVQ8DYyd7ro2dQ__",
      text: "Outstanding",
    },
  ];

  const feedbackData1 = [
    {
      // gif: "https://s3-alpha-sig.figma.com/img/03a9/c8a4/e35687011ba2313ecaa09fc4b050f63c?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bvktaNAm71F9-YzhStkUWKQRdUIj~FDrbDQAXUxEqE2htrDC9O-viHwpdbNiuvxSxXjNU6joA7~39KeklLxBRlddBdhGA2BTq7hkc5yUFAS6d8l8TnrByVjATRq1gDMiqOUsj4F1zDOO2YnTBH14PVWG4RQbY9eV0TIIQPpUZUYTawVBSfBpYWXMRq7wtrUChCZFOZhCoVdeQoitXkTwnqT39iFOR4aVLqBu-xw43oLDyBSJfC69e2SsAlR9Xe5xJehN3pRsHfYAxBIloh64d6Dh9NGat0cyFLDe8GOUHEP0QK-aBd1uY9gZt6vwmbJEPbKTPzJF1Dz5jPBhKz1EAg__",
      gif: "https://s3-alpha-sig.figma.com/img/03a9/c8a4/e35687011ba2313ecaa09fc4b050f63c?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JVrVIA8EwhEZ8Idz~lttnzrh9KE3aIkBEuNipDjS4SjIIcuLSiQ553M~T0Q2mj4-3NZNIwdI6C3qoI7aVAd04ge17wz0Vw6UFqldyX38F-eBNDeaQKNJU28BfdQVMybyAY6jOQV2pQPo1~OaosjgshMFft8WZS-p4WoPKyRda5nd8dp6qjPN0xX23AOuabU6HlMHVLX70et3tRbm07gB45HnKYxJaZl7vp3sIT8ENz6eorQRksa3ts~hzgf04FYOxfgT8H~zgFOu1~KXrA72lrs7NjUlhUsM36cS6vHnsIFGtM4OqkwXDpSJ9h8ANe8GqbHypKKitBjb0FrySH-lPQ__",
      text: "Disappointing",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/b221/1cd2/15af41f80db44a56689429583b89c8ae?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FGsXieayqd~TCZ-QQqIYPv6JMu0O3VZT8uFeM5TOd9frV5DCMXWxwgUKES~ESZpq9qaaeUGTBlwhlpf3Q9yAdv3w1GK6kQAbZV-36tulE5785GR~QQtGvT2fpGoSUA0lqngL7vofbAVFWGcPKuKEmXzBH8lPgtz3rN1VCdH-CRViOqhZqO9OwH~QDsZbctraM-CWDzem-6nZs6ZHGrNwfAY9IJapDFib3o5qCL-kuHOzbvqmlZXfy-7yBqNmyW7gzaAAX7nbxAv309-m8icyqpi-UCv4fYwRBO~dVKGp~9uyuWoDTyp5JLeR0JNRINF82xH7XXM0jMBR4d2fY4gZCQ__",
      gif: "https://s3-alpha-sig.figma.com/img/b221/1cd2/15af41f80db44a56689429583b89c8ae?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Fu0nfoQ5yRfB2DYhPeO6m4E8ICpf4ITiwvmYMnK1AaCKe5FkqHGdtlbnJzSwDYP4oIyasFubfbbMN8CyypLBhdln7wensNSDMX-3J8wgFZAGPEmGWeWBmvae6trAPA1BgxWlqdcgm5KFvIVDh8p3E73Df8YqN83auwAuwmVXi-D9xFJHmhE2WP90S6zUtjNbFm63pD6XuPFotLOrmFLg2lRxfJa8w2RVrovXTzjs~p2cZTSjorampPYGlEaxgWVJG5MUjZObypKeKKC1e0f1CeVBwd3g42KbhKo0P5ft4Yt5W6pb3fEXVHVO3ynRp2WPm0fKrEDlUJrjro3HsY55-Q__",
      text: "Just OK",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dlTVj9cfxHwex3VGsJE4IC2fUfNyHN1YYlL6q0ObDSHxLZL1v2xw97mXkkSTCAcxJDQytT~VgKUuU5NR-qm6-V2KGrIMYtTFwiZoNV2sxNZ7nEzGRriWvdkk6k0quYd1WmTm7-Q0h8jfzTlf4IcW4K5ppsPoBfhRp5D5ZcYAsiY~b~3qB4P24oW0zSDa6o8uLsfoRUVt4fmdcx1I2hZWGAvOwJWi3IaEs9tCQ1mN-ta1denNEhu0F66~XgPGLJpTdUhrFB5LylBvJQoBBVP-zLlrPbzyEXOTlqztX-r3NGpk8ssuHn9O6cn8svExbcFBSfssteXgS~iZqq4ksSnK2g__",
      gif: "https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gij0mj8NCYJD5aWuJhhCntGGV8oLH-geWAkgnRF7zYrZiQAfTZwdV8-9thOHaBArjWHQUEeLk8sHW3TAQ4UINCrWYtoK4DmwvpaE4DksILxL~Ig8C09gdHmDywPFqlGG6g7YnpXusnkZh4a1MtHnn5DRqa~zFX3zDCiggokqm3oWW0MTTgDskByGL-lqqTUY~jbT0knR4mJnoOHzo86bpqoEsufyW1x-kvtsV3KzyNElk07G9djGtW1RlZyaonrYy3iyMAk090A4kJeR57rwEKR2Cs5kK4aPhEgLtpNlH03z5LMzQosGQHAVEgjTcu4-IHewDXULxe9CUlkOBnWDoQ__",
      text: "Satisfactory",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/0f17/b5a3/47afe79f315fc8178f9a03cf6b455405?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=M5Vj~Ik3SSYUDG~UhZIj8PtdCqmdSwashX-FgMxFpqBR7WXS2gyT5FtfRworuuRPPagWrbBB039xOozQA-hxdGpy1tPXGWrkWrhEzMhSJ7~I34vy8M8WxfyGloOZLiKQxc1XVjNCTibBzOgR~PgMgeiqy1ZO4aGLx9hitwCNsWK0EuoA-MwDSot4OxiTu8aNaxve4UpHut5oSLC01d2L6wQqAu56OSLHKztidAMiCauXw2gG7XIRpsLnsobv-c94J3rqzzBConw8qBbnpmSQ6nm9etTw~iSmocGnBrn6WzAI3t70bcNs9haS7Ov8FbdbDpcAELkOCZZbjfk5dfMCNA__",
      gif: "https://s3-alpha-sig.figma.com/img/0f17/b5a3/47afe79f315fc8178f9a03cf6b455405?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=k6ljjnHWeEzy1wXyGmr5dbReYe1z2ef1P6eRqnpv0pJb~y2W0PAFpCHcCJtpp6xUEix54EtLDCRQ3m6OgmisQIRSezX7Er0Jdqis1Vnnl9MAKKlxPDANi6PtVvdRYKyGTSDkD-gQ5RmtFf1gvlhhcoscrcPVKneYI23hbx90O6DIGhIZ2OIvLuDEl3GlyOWcrdSkWT4HvJG3iKJWMLrW8st9rd7lUZGE~o0YqVFFIgaXAj4xeula6IEFo9YCWAqMW~Pa3XaPc6PjtWN52G4PIMfb7p5QuVZsYTN2u~plTjgptk-~zGF82WSKnjlnrLtgTssc46lJWhvqZIaVpmA7FA__",
      text: "Impressive",
    },
    {
      // gif: "https://s3-alpha-sig.figma.com/img/0975/2a13/d8f893cb3f1cb5938e1253295f70e9e3?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QkLQmtPqIpmVjaL5fnVLejOhFAxqFwOPFm6vfLZt3Fl3LaCqRkNHoq-Nh53xm9zZdCnPax0Sd9PbfIiz6o8slvun~ehbPK1MNqrUQ52dPlGOTCeWOh15yoDma92Jro~3ZfrVAI5qtO4Q9~Ew4DjG~QNBIw5HNeaFdSMzkeMVL-s7cIWWtdqEqeRvwrOh4Qe5BWq3yrJQEG13l3pgTrQRcM5R5tX619Yz7N4PFooF9PErsyqvRuR~42yJAkagr77xjh-9bS5nFaEBBIxxBq88f6w6OYogNyjdyv5YSiagb9sUloDeGOpXHOz4DXqj0yFWXOHRh9QDjw0Xt4JJcrRp5w__",
      gif: "https://s3-alpha-sig.figma.com/img/0975/2a13/d8f893cb3f1cb5938e1253295f70e9e3?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DryEIPVlmjFyglyBLK3ry0blP0AEsQt9iElHCQce~gKau-a06L3Z5yr9YU1AUQoK1pofI0ARsD1A1uIDwBDpS2vwhPT1O08QqoxLfiDRRTPyCuQo73jilt9tuimnG8Vwv6y18urZ8F0ZzzO9u6SOgliF2F4UoRYz4hVeDli7MCwor7OGeJTPwVHNM1v-hnD42Pr05maiouxtg~pqFvAqnLfR0MGfpaB5qLmuKWJyzKTBhSvsZOQhxhclWmwif313Zg1Riez7m3e3wMU2ReNSBJvKG2GC5K3VW5Em~q99P7RaED7ABiDgB6tBh34UMA207feTRWEOtVQ8DYyd7ro2dQ__",
      text: "Outstanding",
    },
  ];

  const renderInputField = (feedbackLine) => {
    switch (feedbackLine.question_type) {
      case "Multiple Choice":
        return (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "35px", marginLeft: "10vw" }}>
            {feedbackLine.option_list &&
              feedbackLine.option_list.map((option, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    name={feedbackLine.title}
                    value={option}
                    checked={selectedFeatures[feedbackLine.title] === option}
                    onChange={(e) => handleCheckboxChange(feedbackLine.title, e.target.value)}
                    id={`option-${index}`}
                    style={{ transform: "scale(1.4)", margin: 0 }}
                  />
                  <label htmlFor={`option-${index}`} style={{ marginLeft: "12px", fontSize: "20px" }}>
                    {option}
                  </label>
                </div>
              ))}
          </div>
        );

      case "Rating":
        const maxRating = feedbackLine.max_rating || 5;
        return (
          <div style={{ display: "flex", gap: "20px", marginLeft: "11vw" }}>
            {[...Array(maxRating).keys()].map((index) => (
              <button
                key={index}
                onClick={() => handleRatingChange(index + 1)}
                style={{
                  display: "inline-block",
                  width: "70px",
                  height: "70px",
                  lineHeight: "70px",
                  textAlign: "center",
                  borderRadius: "50%",
                  backgroundColor: index + 1 <= recommendation ? "#FFF" : "transparent",
                  color: index + 1 <= recommendation ? "#000" : "#FFF",
                  border: "1px solid #FFF",
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        );

      case "Text":
        return (
          <textarea
            value={improvement}
            placeholder={feedbackLine.placeholder_text || ""}
            onChange={(e) => handleInputChange("Improvement", e.target.value)}
            style={{ width: "100%", height: "100px", resize: "none", color: "#0F0718" }}
          />
        );

      default:
        return null;
    }
  };

  const getCurrentPageItems = () => {
    if (!feedbackJson) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return feedbackJson.feedbackLines.slice(startIndex, endIndex);
  };

  return (
    <div style={{ ...mainDivStyles, display: "flex" }}>
      <div className="customer-login">
        <img
          src={kisokLogo}
          style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" }}
          onClick={() => {
            localStorage.setItem("layoutType", 0);
            const customEvent1 = new CustomEvent("customStorageChange", {
              detail: { key: "layoutType", newValue: 0 },
            });
          }}
        />
        <p className="user-name">Hey, {userName}</p>
      </div>
      {layoutType === 0 ? (
        //   <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
        //     <p style={{ fontSize: "2.15vw", color: "#fff", textAlign: "center", fontWeight: "500" }}>Choose Your Preferred Payment Method</p>
        //     <div style={{ display: "flex", paddingTop: "3.4%", paddingBottom: "8vh", justifyContent: "start", width: "100%", paddingLeft: "8%" }}>
        //     {_.sortBy(tillDataPaymentMethods, "sequenceNo").map((pm, ind) => {
        // return posConfig.loyaltyApplicable === "N" && pm.name === "Loyalty" ? null : (
        //           <div
        //             style={{
        //               height: "32.5vh",
        //               width: "18.2vw",
        //               backgroundColor: "#626367",
        //               display: "flex",
        //               alignItems: "center",
        //               flexDirection: "column",
        //               marginRight: "4.1%",
        //               borderRadius: "10px",
        //             }}
        //             onClick={() => {
        //               requestPayment(pm, cart.total);
        //               openPaymentModalByCustomer();
        //               setTimeout(() => {
        //                 setKioskUI(0);
        //                 localStorage.setItem("kioskUI", 0);
        //                 localStorage.setItem("layoutType", 0);
        //                 const customEvent1 = new CustomEvent("customStorageChange", {
        //                   detail: { key: "layoutType", newValue: 0 },
        //                 });
        //                 window.dispatchEvent(customEvent1);
        //                 const customEvent = new CustomEvent("customStorageChange", {
        //                   detail: { key: "kioskUI", newValue: 0 },
        //                 });
        //                 window.dispatchEvent(customEvent);
        //               }, 1000);
        //               // setTimeout(() => {
        //               //   // setKioskUI(0);
        //               //   // localStorage.setItem("kioskUI", 0);
        //               //   if (posConfig.enableCardIntegration === "Y") {
        //               //     setLayoutType(1);
        //               //     localStorage.setItem("layoutType", 1);
        //               //     const customEvent1 = new CustomEvent("customStorageChange", {
        //               //       detail: {key: "layoutType", newValue: 0 },
        //               //     });
        //               //     window.dispatchEvent(customEvent1);
        //               //     const customEvent = new CustomEvent("customStorageChange", {
        //               //       detail: { key: "kioskUI", newValue: 0 },
        //               //     });
        //               //     window.dispatchEvent(customEvent);
        //               //   }
        //               // }, 1000);
        //             }}
        //            >
        //             <div style={{ height: "18vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        //               <img src={CrediCard} style={{ height: "9vh" }} />
        //             </div>

        //             <p
        //               style={{
        //                 wordBreak: "break-word",
        //                 fontSize: "2vw",
        //                 color: "#fff",
        //                 width: "60%",
        //                 textAlign: "center",
        //                 lineHeight: "1.2",
        //                 position: "relative",
        //                 fontWeight: "500",
        //               }}
        //              >
        //               {pm.name}
        //             </p>
        //           </div>
        // );
        //     })}
        //     </div>
        //   </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: "2.15vw",
              // color: themeJSON.orderNoTextColor || "#fff",
              color: "#fff",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            Choose Your Preferred Payment Method
          </p>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "3.4%",
              paddingBottom: "8vh",
              width: "100%",
              paddingLeft: "4%",
            }}
          >
            {_.sortBy(tillDataPaymentMethods, "sequenceNo")
              .filter((pm) => ["cash", "upi", "card"].includes(pm.name.toLowerCase())) // Filter payment methods
              .map((pm, ind) => (
                <div
                  key={ind}
                  style={{
                    height: "32.5vh",
                    width: "18.2vw",
                    // backgroundColor: "#626367",
                    backgroundColor: themeJSON.productTableBGColor || "#626367",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginRight: "4.1%",
                    borderRadius: "10px",
                    cursor: "pointer",
                    justifyContent: "center",
                    marginBottom: "4%",
                  }}
                  onClick={() => {
                    requestPayment(pm, cart.total);
                    openPaymentModalByCustomer();
                    setTimeout(() => {
                      setLayoutType(1);
                      localStorage.setItem("layoutType", 1);
                      const customEvent1 = new CustomEvent("customStorageChange", {
                        detail: { key: "layoutType", newValue: 1 },
                      });
                      window.dispatchEvent(customEvent1);
                      const customEvent = new CustomEvent("customStorageChange", {
                        detail: { key: "kioskUI", newValue: 0 },
                      });
                      window.dispatchEvent(customEvent);
                    }, 1000);
                  }}
                >
                  <p style={{ color: "#fff", fontSize: "1em", paddingTop: "7vh", fontSize: "2vw" }}>
                    {pm.name.toLowerCase() === "cash" ? (
                      <>
                        <img src={cashIcon} style={{ height: "5vh", marginBottom: "0.2em", marginLeft: "0.6em" }} />
                        <p>{pm.name}</p>
                      </>
                    ) : pm.name.toLowerCase() === "upi" ? (
                      <>
                        <img src={Qrcode} style={{ height: "5vh", marginBottom: "0.2em", marginLeft: "0.3em" }} />
                        <p>{pm.name}</p>
                      </>
                    ) : pm.name.toLowerCase() === "card" ? (
                      <>
                        <img src={CrediCard} style={{ height: "5vh", marginBottom: "0.2em", marginLeft: "0.6em" }} />
                        <p>{pm.name}</p>
                      </>
                    ) : null}
                  </p>
                </div>
              ))}
          </div>
        </div>
      ) : layoutType === 1 ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column", position: "relative" }}>
          <img src={Sucess} style={{ height: "30vh", position: "absolute", left: 0, right: 0, top: "2%", margin: "0 auto" }} />
          <p style={{ fontSize: "2.6vw", color: "#fff", textAlign: "center", fontWeight: "500", paddingTop: "11vh", margin: "0" }}>Payment Successful</p>
          <p style={{ fontSize: "3.3vw", color: "#fff", textAlign: "center", fontWeight: "500", paddingTop: "2vh", margin: "0", paddingBottom: "3vh" }}>
            How would you like your receipt ?
          </p>
          <div style={{ display: "flex", paddingTop: "2.4%", paddingBottom: "8vh", justifyContent: "center", width: "100%" }}>
            {posConfig.eBill === "Y" ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    height: "25.5vh",
                    width: "18.2vw",
                    // backgroundColor: "#626367",
                    backgroundColor: themeJSON.productTableBGColor || "#626367",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "8%",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  // onClick={() => setLayoutType(5)}
                  onClick={() => {
                    if (posConfig.enableCustomerFeedback === "Y") {
                      setLayoutType(5);
                    } else {
                      localStorage.setItem("kioskUI", 0);
                      setKioskUI(0);
                      const customEvent = new CustomEvent("customStorageChange", {
                        detail: { key: "kioskUI", newValue: 0 },
                      });
                      window.dispatchEvent(customEvent);
                    }
                  }}
                >
                  <img src={Receipt} style={{ height: "7vh" }} />
                  <p
                    style={{
                      wordBreak: "break-word",
                      fontSize: "2vw",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      paddingTop: "1vh",
                      lineHeight: "1.2",
                      position: "relative",
                      bottom: "-4vh",
                      fontWeight: "500",
                    }}
                  >
                    Print Receipt
                  </p>
                </div>
                <div
                  style={{
                    height: "25.5vh",
                    width: "18.2vw",
                    // backgroundColor: "#626367",
                    backgroundColor: themeJSON.productTableBGColor || "#626367",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  // onClick={() => setLayoutType(5)}
                  onClick={() => {
                    if (posConfig.enableCustomerFeedback === "Y") {
                      setLayoutType(5);
                    } else {
                      localStorage.setItem("kioskUI", 0);
                      setKioskUI(0);
                      const customEvent = new CustomEvent("customStorageChange", {
                        detail: { key: "kioskUI", newValue: 0 },
                      });
                      window.dispatchEvent(customEvent);
                    }
                  }}
                >
                  <img src={SMS} style={{ height: "7vh" }} />
                  <p
                    style={{
                      wordBreak: "break-word",
                      fontSize: "2vw",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      paddingTop: "1vh",
                      lineHeight: "1.2",
                      position: "relative",
                      bottom: "-4vh",
                      fontWeight: "500",
                    }}
                  >
                    Digital Receipt
                  </p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "25.5vh",
                  width: "18.2vw",
                  // backgroundColor: "#626367",
                  backgroundColor: themeJSON.productTableBGColor || "#626367",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginRight: "8%",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // onClick={() => setLayoutType(5)}
                onClick={() => {
                  if (posConfig.enableCustomerFeedback === "Y") {
                    setLayoutType(5);
                  } else {
                    localStorage.setItem("kioskUI", 0);
                    setKioskUI(0);
                    const customEvent = new CustomEvent("customStorageChange", {
                      detail: { key: "kioskUI", newValue: 0 },
                    });
                    window.dispatchEvent(customEvent);
                  }
                }}
              >
                <img src={Receipt} style={{ height: "7vh" }} />
                <p
                  style={{
                    wordBreak: "break-word",
                    fontSize: "2vw",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "1vh",
                    lineHeight: "1.2",
                    position: "relative",
                    bottom: "-4vh",
                    fontWeight: "500",
                  }}
                >
                  Print Receipt
                </p>
              </div>
            )}
          </div>
        </div>
      ) : layoutType === 3 ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
          <div style={{ display: "flex", paddingTop: "3.4%", paddingBottom: "8vh", justifyContent: "center", width: "100%" }}>
            <div
              style={{
                height: "43vh",
                width: "22vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginRight: "5%",
                borderRadius: "10px",
              }}
              onClick={() => setLayoutType(1)}
            >
              <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={Cashup} style={{ height: "15vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                  margin: "9%",
                }}
              >
                Cash Up Details
              </p>
              <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

            <div
              style={{
                height: "43vh",
                width: "22vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginRight: "5%",
                borderRadius: "10px",
              }}
              onClick={() => setLayoutType(1)}
            >
              <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={Sync} style={{ height: "15vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                  margin: "9%",
                }}
              >
                Data Sync
              </p>
              <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

            <div
              style={{
                height: "43vh",
                width: "22vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                //   marginRight: "5%",
                borderRadius: "10px",
              }}
              onClick={() => setLayoutType(1)}
            >
              <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={Settings} style={{ height: "15vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                  margin: "9%",
                }}
              >
                Kiosk Settings
              </p>
              <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>
          </div>
        </div>
      ) : layoutType === 4 ? (
        <div style={{ display: "flex", justifyContent: "center", width: "100%", flexDirection: "column", color: "#FFF", alignItems: "center" }}>
          {feedbackJson?.feedbackLines?.map((feedbackLine, index) => (
            <div key={index} style={{ marginBottom: "20px", marginTop: "30px" }}>
              <div style={{ fontSize: "23px", marginBottom: "15px", fontWeight: "300" }}>
                {feedbackLine.title}
                {feedbackLine.is_required === "Y" && <span style={{ color: "red" }}>*</span>}
              </div>
              {renderInputField(feedbackLine)}
            </div>
          ))}

          <div style={{ display: "flex", width: "86.5%", marginTop: "20px", paddingLeft: "57vw" }}>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{
                //  backgroundColor: "#EEC302",
                backgroundColor: themeJSON.btnsBgColor || "#EEC302",
                color: "#FFF",
                borderRadius: "5px",
                width: "11vw",
                height: "7.5vh",
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : layoutType === 5 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
            color: "#FFF",
          }}
        >
          <div>
            {rating > 0 ? (
              <img src={feedbackData1[rating - 1].gif} alt={feedbackData[rating - 1].text} style={{ width: "150px", height: "150px" }} />
            ) : (
              // https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BqYsqHk6IPytFDCjYlMN84L1EWhfQVAZtFc1V9D36x56-Yjp9U-pwRf00PrCj4xThhbDXy1z385bAevf~r9F-GWoCylnpYGWlC4VqnchNEgCwPKHh~nAMaKceRSfzrrysYTtH2yR8Ol56tmxsFFXuITuc8EJtgiTf03yIuJJMv9tlM~j7RsY~VozCB7A7ajMJN9tIA58fbaKZEkDjre6xwLsof5uT2I86hJ3T1VMwuDOamPRuatki2rlZiS0~XUXbzE5Zqwa7had~F8jP1rE4fK052OOtGoeQnSXgc2mQjHqXS1PQJ54i060lqBtWRCT2rIBpLX2dKlM2SsChBTcHw__
              // <img src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=I7rZpJJRybQy8OaQYbMDMbwUgHppJ2DSblWS2pRuut3-P-P49DFaUQcAYVGse8oilLtkl4haMcuaayJ7o9vFvl6~Pht7cfxACVrTFggMA2Kk-To~h4e06Ru-rsSwJt18xrZk81APpJDNWtXFCo6PI3aGelMeAwVtAo6kNJ3-VNsGvaDz8OjYQBn5VzD9O0H4OLwjQ1~8iVriH8pGgAHl3BJJ5U~FFkyYCfjH~X7fdnBlKKObAn-NrNWLc~CA7y3T~1MqiQ6F1-SS1iC8UiZYJtmWycYbDBoBfNtyozsQqipCCDDEMEsVgE4Fh1CcQ7h-H7I3o6TckuUnUHjVvBgYpg__" alt="Default" style={{ width: '150px', height: '150px' }} />
              <img
                // src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BqYsqHk6IPytFDCjYlMN84L1EWhfQVAZtFc1V9D36x56-Yjp9U-pwRf00PrCj4xThhbDXy1z385bAevf~r9F-GWoCylnpYGWlC4VqnchNEgCwPKHh~nAMaKceRSfzrrysYTtH2yR8Ol56tmxsFFXuITuc8EJtgiTf03yIuJJMv9tlM~j7RsY~VozCB7A7ajMJN9tIA58fbaKZEkDjre6xwLsof5uT2I86hJ3T1VMwuDOamPRuatki2rlZiS0~XUXbzE5Zqwa7had~F8jP1rE4fK052OOtGoeQnSXgc2mQjHqXS1PQJ54i060lqBtWRCT2rIBpLX2dKlM2SsChBTcHw__"
                src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gij0mj8NCYJD5aWuJhhCntGGV8oLH-geWAkgnRF7zYrZiQAfTZwdV8-9thOHaBArjWHQUEeLk8sHW3TAQ4UINCrWYtoK4DmwvpaE4DksILxL~Ig8C09gdHmDywPFqlGG6g7YnpXusnkZh4a1MtHnn5DRqa~zFX3zDCiggokqm3oWW0MTTgDskByGL-lqqTUY~jbT0knR4mJnoOHzo86bpqoEsufyW1x-kvtsV3KzyNElk07G9djGtW1RlZyaonrYy3iyMAk090A4kJeR57rwEKR2Cs5kK4aPhEgLtpNlH03z5LMzQosGQHAVEgjTcu4-IHewDXULxe9CUlkOBnWDoQ__"
                alt="Default"
                style={{ width: "150px", height: "150px" }}
              />
            )}
          </div>
          {rating > 0 && (
            <div
              style={{
                // marginTop: "10px",
                fontSize: "45px",
                fontFamily: "Inter",
                fontWeight: 500,
                lineHeight: "normal",
                fontStyle: "normal",
                color: "#FFF",
              }}
            >
              {feedbackData[rating - 1].text}
            </div>
          )}

          <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
            <Rate
              onChange={handleChange}
              //  onClick={()=>{setLayoutType(4)}}
              value={rating}
              style={{ fontSize: "100px", display: "flex", justifyContent: "space-between", width: "57%" }}
            />
          </div>
          <hr
            style={{
              borderColor: "gray",
              width: "50%",
              margin: "20px 0",
              borderWidth: "1px",
            }}
          />
          <div
            style={{
              marginTop: "10px",
              fontSize: "40px",
              fontFamily: "Inter",
              fontWeight: 500,
              lineHeight: "normal",
              fontStyle: "normal",
              color: "#FFF",
            }}
          >
            {getFeedbackName()}
          </div>
        </div>
      ) : null}

      {layoutType === 0 ? (
        <div style={{ position: "absolute", right: "3%", bottom: "4vh" }}>
          <p style={{ fontSize: "2.15vw", color: "#fff", margin: "0", fontWeight: "500" }}>
            Total Amount To Pay {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
            &nbsp;
            {parseFloat(cart.total).toFixed(2)}
            &nbsp;
            {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
          </p>
        </div>
      ) : null}

      {/* {layoutType === 3 ? (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img src={backImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      ) : (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img
              src={backImg}
              style={{ height: "2.7vw" }}
              onClick={() => {
                setKioskUI(3);
                localStorage.setItem("layoutType", 1);
                const customEvent1 = new CustomEvent("customStorageChange", {
                  detail: { key: "layoutType", newValue: 1 },
                });
                window.dispatchEvent(customEvent1);
                localStorage.setItem("kioskUI", 3);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "kioskUI", newValue: 3 },
                });
                window.dispatchEvent(customEvent);
              }}
            />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={volumeImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Volume</p>
          </div>
          <div className="bottom-img">
            <img src={wheelchairImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      )} */}

      {layoutType === 3 ? (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img src={backImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      ) : layoutType === 0 ? (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img
              src={backImg}
              style={{ height: "2.7vw" }}
              onClick={() => {
                setKioskUI(3);
                localStorage.setItem("layoutType", 1);
                const customEvent1 = new CustomEvent("customStorageChange", {
                  detail: { key: "layoutType", newValue: 1 },
                });
                window.dispatchEvent(customEvent1);
                localStorage.setItem("kioskUI", 3);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "kioskUI", newValue: 3 },
                });
                window.dispatchEvent(customEvent);
              }}
            />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={volumeImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Volume</p>
          </div>
          <div className="bottom-img">
            <img src={wheelchairImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      ) : layoutType === 1 || layoutType === 5 || layoutType === 4 ? (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img src={volumeImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Volume</p>
          </div>
          <div className="bottom-img">
            <img src={wheelchairImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PaymentSelection;
