import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Typography } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchOutlined, CloseSquareFilled } from "@ant-design/icons";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import Calender from "../../../../assets/images/calender.svg";
import "../restaurantStyle.css";
import moment from "moment";

const TableLayout = ({
  tableCards,
  restaurantSections,
  mainTableData,
  setTableCards,
  handleTable,
  handleReserveTable,
  occupiedDataTablesData,
  reservedTablesData,
  userPreferences,
}) => {
  const [selectedFloor, setSelectedFloor] = useState({ value: "all", name: "All" });
  const [reservedButtonFlag, setReservedButtonFlag] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [currentTable, setCurrentTable] = useState({});
  const currentDateTime = useState(moment(new Date()).format(userPreferences.dateFormat));
  const tableData = tableCards;
  const { Text } = Typography;

  const handleButtonClick = (floor) => {
    let tempData = [];
    if (floor.name === "All") {
      tempData.push(...mainTableData);
      setSelectedFloor(floor);
    } else {
      mainTableData.map((e) => {
        if (e.cwrFbsectionId === floor.value) {
          tempData.push(e);
          setSelectedFloor(floor);
        }
      });
    }
    setTableCards(tempData);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "73%", height: "100vh" }}>
        <Row style={{ fontSize: "1vw", paddingRight: 0 }}>
          <Col span={18} className="floor-buttons">
            {restaurantSections?.map((section) => (
              <Button className={`floor-button ${selectedFloor.name === section.name ? "selected" : ""}`} onClick={() => handleButtonClick(section)}>
                {section.name}
              </Button>
            ))}
          </Col>
          <Col span={5}>
            {/* <img src={Calender} style={{height:"3vh",}}/> */}
            <div style={{ fontWeight: 500, fontFamily: "Inter", fontSize: "1.25em", color: "#929098", paddingTop: "3.4vh", paddingLeft: "1vw" }}>
              <img src={Calender} style={{ height: "3vh", paddingRight: "0.3em", paddingBottom: "0.1rem" }} />
              {currentDateTime}
            </div>
          </Col>
        </Row>
        <div className="tables-container">
          <Scrollbars autoHide style={{ height: "77vh" }}>
            <InfiniteScroll
              dataLength={tableCards.length}
              hasMore={true}
              endMessage={
                <p className="center">
                  <b>Yay! you have seen it all</b>
                </p>
              }
              style={{ overflowX: "hidden" }}
            >
              <Row gutter={[16, 16]} style={{ rowGap: "11px" }}>
                {tableData.map((table) => (
                  <Col key={table.id} xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Card
                      className={`table-card ${table.statusType}`}
                      onClick={() => {
                        handleTable(table);
                      }}
                    >
                      <div className="table-title">{table.name}</div>
                      <div className="table-status">
                        {table.statusType === "OCU" ? "Checked-in" : table.statusType === "OPN" ? "Available" : "Reserved"}{" "}
                        {/* {table.statusType !== "OPN" ? table?.time && `| ${table?.time}` : null} */}
                        <div className="table-status">
                          {table.statusType === "RES" ? `${table?.reservedTime}` : table.statusType === "OCU" && table?.time ? `${table.time}` : null}
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          </Scrollbars>
          <div>
            <Row
              style={{
                backgroundColor: "#fff",
                height: "8vh",
                borderRadius: "30px",
                padding: "0 1vw",
                position: "absolute",
                width: "95.5%",
                bottom: "-6vh",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Col span={14}>
                <div
                  class="status-indicator"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "8vh",
                    fontSize: "1vw",
                  }}
                >
                  <span class="available">Available</span>
                  <span class="reserved">Reserved</span>
                  <span class="checked-in">Checked-in</span>
                </div>
              </Col>
              <Col
                span={10}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  height: "8vh",
                  fontSize: "1vw",
                }}
              >
                <Button
                  style={{ backgroundColor: "#2F3856", color: "#fff", borderRadius: "10px", height: "6vh", fontSize: "1em", fontWeight: "500", fontFamily: "Inter" }}
                  onClick={handleReserveTable}
                >
                  Reserve Table
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div style={{ background: "#fff", height: "100vh", width: "27%", fontSize: "1vw", overflow: "hidden" }}>
        <div style={{ padding: "0 1vw" }}>
          {/* <Input
            placeholder="Search here"
            // prefix={<SearchOutlined />}
            prefix={<img src={SearchIcon} alt="" style={{ paddingLeft: "5px", paddingRight: 0, height: "2.4vh" }} />}
            className="search-input"
          /> */}

          <Input
            placeholder="Search here"
            // prefix={<SearchOutlined />}
            prefix={<img src={SearchIcon} alt="" style={{ paddingLeft: "5px", paddingRight: 0, height: "2.4vh" }} />}
            className="search-input"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {searchInput.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856", cursor: "pointer" }} onClick={() => setSearchInput("")} /> : null}
              </div>
            }
          />
        </div>
        <Row>
          <Col span={12} style={{ padding: "2vh 0.5vw 1vh 1vw" }}>
            <Button
              style={{
                backgroundColor: reservedButtonFlag ? "#fff" : "#2F3856",
                color: reservedButtonFlag ? "#929098" : "#fff",
                borderRadius: "35px",
                width: "100%",
                fontSize: "1em",
                fontWeight: "500",
                height: "5.5vh",
              }}
              onClick={() => {
                setReservedButtonFlag(false);
              }}
            >
              Reserved {reservedTablesData.length}
            </Button>
          </Col>
          <Col span={12} style={{ padding: "2vh 1vw 1vh 0.5vw" }}>
            <Button
              style={{
                backgroundColor: !reservedButtonFlag ? "#fff" : "#2F3856",
                color: !reservedButtonFlag ? "#929098" : "#fff",
                borderRadius: "35px",
                width: "100%",
                fontSize: "1em",
                fontWeight: "500",
                height: "5.5vh",
              }}
              onClick={() => {
                setReservedButtonFlag(true);
              }}
            >
              Checked-in {occupiedDataTablesData.length}
            </Button>
          </Col>
        </Row>

        {/* <Scrollbars autoHide style={{ height: "75vh" }}>
          <div>
            {reservedButtonFlag
              ? occupiedDataTablesData.map((occupiedData) => {
                  return (
                    <Card
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                      bodyStyle={{
                        padding: "1vh",
                      }}
                      onClick={() => setSelectedTable(occupiedData)}
                    >
                      <div
                        style={{
                          backgroundColor: occupiedData.name === selectedTable.name ? "#F3F4F9" : "#fff",
                          borderRadius: 25,
                          display: "flex",
                          alignItems: "center",
                          height: "7vh",
                          fontSize: "1vw",
                        }}
                      >
                        <Text strong style={{ textAlign: "left", width: "18vw" }}>
                          <span style={{ paddingRight: "2vw", paddingLeft: "1vw", fontSize: "1em", color: "#0F0718", fontWeight: 500 }}>{occupiedData.name}</span>{" "}
                          <span>{occupiedData.guestName}</span>
                        </Text>
                        <Text style={{ textAlign: "right", fontSize: "1em", color: "#929098", fontWeight: 500 }}>{occupiedData.noOfPersons} Persons</Text>
                      </div>
                    </Card>
                  );
                })
              : reservedTablesData.map((reservedData) => {
                  return (
                    <Card
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                      bodyStyle={{
                        padding: "1vh",
                      }}
                      onClick={() => setSelectedTable(reservedData)}
                    >
                      <div
                        style={{
                          backgroundColor: reservedData.name === selectedTable.name ? "#F3F4F9" : "#fff",
                          borderRadius: 25,
                          display: "flex",
                          alignItems: "center",
                          height: "7vh",
                        }}
                      >
                        <Text strong style={{ textAlign: "left", width: "18vw" }}>
                          <span style={{ paddingRight: "2vw", paddingLeft: "1vw", fontSize: "1em", color: "#0F0718", fontWeight: 500 }}>{reservedData.name}</span>{" "}
                          <span>{reservedData.guestName}</span>
                        </Text>
                        <Text style={{ textAlign: "right", fontSize: "1em", color: "#929098", fontWeight: 500 }}>{reservedData.noOfPersons} Persons</Text>
                      </div>
                    </Card>
                  );
                })}
          </div>
        </Scrollbars> */}

        {/* <Scrollbars autoHide style={{ height: "66vh" }}>
          <div>
            {reservedButtonFlag
              ? occupiedDataTablesData
                .filter(
                  (occupiedData) =>
                    occupiedData.name
                      ?.toLowerCase()
                      .includes(searchInput.toLowerCase()) ||
                    occupiedData.guestName
                      ?.toLowerCase()
                      .includes(searchInput.toLowerCase())
                )
                .map((occupiedData) => {
                  console.log("Occupied Data: ", occupiedData);
                  return (
                    <Card
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                      bodyStyle={{
                        padding: "1vh",
                      }}
                      onClick={() => setCurrentTable(occupiedData)}
                    >
                      <div
                        style={{
                          backgroundColor:
                            occupiedData.name === currentTable.name
                              ? "#F3F4F9"
                              : "#fff",
                          borderRadius: 25,
                          display: "flex",
                          alignItems: "center",
                          height: "7vh",
                          fontSize: "1vw",
                          cursor: "pointer"
                        }}
                      >
                        <Text
                          strong
                          style={{ textAlign: "left", width: "18vw" }}
                        >
                          <span
                            style={{
                              paddingRight: "2vw",
                              paddingLeft: "1vw",
                              fontSize: "1em",
                              color: "#0F0718",
                              fontWeight: 500,
                            }}
                          >
                            {occupiedData.name}
                          </span>{" "}
                          <span>{occupiedData.guestName}</span>
                        </Text>
                        <Text
                          style={{
                            textAlign: "right",
                            fontSize: "1em",
                            color: "#929098",
                            fontWeight: 500,
                          }}
                        >
                          {occupiedData.noOfPersons} Persons
                        </Text>
                      </div>
                    </Card>
                  );
                })
              : reservedTablesData
                .filter(
                  (reservedData) =>
                    reservedData.name
                      ?.toLowerCase()
                      .includes(searchInput.toLowerCase()) ||
                    reservedData.guestName
                      ?.toLowerCase()
                      .includes(searchInput.toLowerCase())
                )
                .map((reservedData) => {
                  return (
                    <Card
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                      bodyStyle={{
                        padding: "1vh",
                      }}
                      onClick={() => setCurrentTable(reservedData)}
                    >
                      <div
                        style={{
                          backgroundColor:
                            reservedData.name === currentTable.name
                              ? "#F3F4F9"
                              : "#fff",
                          borderRadius: 25,
                          display: "flex",
                          alignItems: "center",
                          height: "7vh",
                        }}
                      >
                        <Text
                          strong
                          style={{ textAlign: "left", width: "18vw" }}
                        >
                          <span
                            style={{
                              paddingRight: "2vw",
                              paddingLeft: "1vw",
                              fontSize: "1em",
                              color: "#0F0718",
                              fontWeight: 500,
                            }}
                          >
                            {reservedData.name}
                          </span>{" "}
                          <span>{reservedData.guestName}</span>
                        </Text>
                        <Text
                          style={{
                            textAlign: "right",
                            fontSize: "1em",
                            color: "#929098",
                            fontWeight: 500,
                          }}
                        >
                          {reservedData.noOfPersons} Persons
                        </Text>
                      </div>
                    </Card>
                  );
                })}
          </div>
        </Scrollbars>

        {reservedButtonFlag && (
          <Row style={{ position: "relative", bottom: "-1vh", display: currentTable ? "block" : "none" }}>
            <Col span={12} style={{ padding: "0 0.5vw 0 1vw" }}>
              <Button
                style={{
                  background: "#fff",
                  color: "#2F3856",
                  borderRadius: "10px",
                  width: "100%",
                  fontSize: "1em",
                  fontWeight: "500",
                  height: "6vh"
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={12} style={{ padding: "0 1vw 0 0.5vw" }}>
              <Button
                onClick={() => handleTable(currentTable)} // Pass the selected table to handleTable
                style={{
                  background: "#2F3856",
                  color: "#fff",
                  borderRadius: "10px",
                  width: "100%",
                  fontSize: "1em",
                  fontWeight: "500",
                  height: "6vh"
                }}
              >
                Place order
              </Button>
            </Col>
          </Row>
        )} */}

        <Scrollbars autoHide style={{ height: "66vh" }}>
          <div>
            {reservedButtonFlag
              ? occupiedDataTablesData
                  ?.filter(
                    (occupiedData) =>
                      occupiedData.name?.toLowerCase().includes(searchInput.toLowerCase()) || occupiedData.guestName?.toLowerCase().includes(searchInput.toLowerCase())
                  )
                  ?.map((occupiedData) => {
                    const isSelected = occupiedData.name === currentTable?.name;
                    return (
                      <Card
                        style={{
                          width: "100%",
                          border: "none",
                        }}
                        bodyStyle={{
                          padding: "1vh",
                        }}
                        onClick={() => setCurrentTable(occupiedData)}
                      >
                        <div
                          style={{
                            backgroundColor: isSelected ? "#F3F4F9" : "#fff",
                            borderRadius: 25,
                            display: "flex",
                            alignItems: "center",
                            height: "7vh",
                            fontSize: "1vw",
                            cursor: "pointer",
                          }}
                        >
                          <Text strong style={{ textAlign: "left", width: "18vw" }}>
                            <span
                              style={{
                                paddingRight: "2vw",
                                paddingLeft: "1vw",
                                fontSize: "1em",
                                color: "#0F0718",
                                fontWeight: 500,
                              }}
                            >
                              {occupiedData?.name}
                            </span>{" "}
                            <span>{occupiedData?.guestName}</span>
                          </Text>
                          <Text
                            style={{
                              textAlign: "right",
                              fontSize: "1em",
                              color: "#929098",
                              fontWeight: 500,
                            }}
                          >
                            {occupiedData.noOfPersons} Persons
                          </Text>
                        </div>
                      </Card>
                    );
                  })
              : reservedTablesData
                  ?.filter(
                    (reservedData) =>
                      reservedData.name?.toLowerCase().includes(searchInput.toLowerCase()) || reservedData.guestName?.toLowerCase().includes(searchInput.toLowerCase())
                  )
                  ?.map((reservedData) => {
                    const isSelected = reservedData.name === currentTable?.name;
                    return (
                      <Card
                        style={{
                          width: "100%",
                          border: "none",
                        }}
                        bodyStyle={{
                          padding: "1vh",
                        }}
                        onClick={() => setCurrentTable(reservedData)}
                      >
                        <div
                          style={{
                            backgroundColor: isSelected ? "#F3F4F9" : "#fff",
                            borderRadius: 25,
                            display: "flex",
                            alignItems: "center",
                            height: "7vh",
                          }}
                        >
                          <Text strong style={{ textAlign: "left", width: "18vw" }}>
                            <span
                              style={{
                                paddingRight: "2vw",
                                paddingLeft: "1vw",
                                fontSize: "1em",
                                color: "#0F0718",
                                fontWeight: 500,
                              }}
                            >
                              {reservedData?.name}
                            </span>{" "}
                            <span>{reservedData?.guestName}</span>
                          </Text>
                          <Text
                            style={{
                              textAlign: "right",
                              fontSize: "1em",
                              color: "#929098",
                              fontWeight: 500,
                            }}
                          >
                            {reservedData.noOfPersons} Persons
                          </Text>
                        </div>
                      </Card>
                    );
                  })}
          </div>
        </Scrollbars>

        {reservedButtonFlag && (
          <Row
            style={{
              paddingTop: "8vh",
              display: "flex", // Use flexbox to display items side by side
              justifyContent: "space-between", // Ensures space between the buttons
              display: currentTable.name ? "flex" : "none", // Show only if currentTable is selected
            }}
          >
            <Col
              span={11} // Set the width of the button column to 11 to leave some space between buttons
              style={{
                padding: "0 0.5vw 0 1vw",
                display: "flex", // Flexbox for individual column items
                justifyContent: "flex-start", // Align button to the left
              }}
            >
              <Button
                style={{
                  background: "#fff",
                  color: "#2F3856",
                  borderRadius: "10px",
                  width: "100%", // Ensure button takes full width of column
                  fontSize: "1em",
                  fontWeight: "500",
                  height: "6vh",
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col
              span={11} // Set the width of the button column to 11 to leave some space between buttons
              style={{
                padding: "0 1vw 0 0.5vw",
                display: "flex", // Flexbox for individual column items
                justifyContent: "flex-end", // Align button to the right
              }}
            >
              <Button
                onClick={() => handleTable(currentTable)} // Pass the selected table to handleTable
                style={{
                  background: "#2F3856",
                  color: "#fff",
                  borderRadius: "10px",
                  width: "100%", // Ensure button takes full width of column
                  fontSize: "1em",
                  fontWeight: "500",
                  height: "6vh",
                }}
              >
                Place order
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TableLayout;
