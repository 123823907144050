import Axios from "axios";
import { message } from "antd";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";

const authHeaders = getOAuthHeaders();
const serverUrl = process.env.REACT_APP_serverUrl;

export const stock = async (props) => {
  const { cart, setCart, tillData, setProductStock, isFinalValidation, setTempCart } = props;
  const buildCartProducts = cart.items.map(({ value, weight }) => `{ productId: "${value}", orderQty: ${weight} }`).join(", ");

  const query = `
      query { 
        verifyStock(order: {
          orderNo: "${cart.documentno}"
          tillId: "${tillData.tillAccess.cwrTill.searchKey}"
          cartItems: [${buildCartProducts}]
        }) {
          orderNo
          tillId
          cartItems {
            productId
            orderQty
            availabilityStatus
            availableQty
          }
        }
      }
    `;

  try {
    const response = await Axios({
      url: serverUrl,
      method: "POST",
      data: { query },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authHeaders.access_token}`,
      },
    });

    if (response.status === 200) {
      const { cartItems } = response.data.data.verifyStock;
      let totalFlag = true;

      const updateCartItems = (item) => {
        const resItem = cartItems.find((resItem) => resItem.productId === item.value);
        if (resItem) {
          if (resItem.availableQty < item.weight) totalFlag = false;
          return {
            ...item,
            availabilityStatus: resItem.availabilityStatus,
            availableQty: resItem.availableQty,
          };
        }
        return item;
      };

      if (isFinalValidation) {
        const updatedCartData = cart.items.map(updateCartItems);
        const updatedCart = { ...cart, items: updatedCartData };
        setCart(updatedCart);
        localStorage.setItem("cartObj", JSON.stringify(updatedCart));
        setTempCart(JSON.parse(JSON.stringify(updatedCart)));

        return totalFlag;
      } else {
        const hasInsufficientStock = cart.items.some((item) => {
          const resItem = cartItems.find((resItem) => resItem?.productId === item?.value && item?.type === "I" && item?.layAway !== "Y");
          return resItem && resItem.availableQty < item.weight;
        });

        if (hasInsufficientStock && tillData.tillAccess.csBunit.allowNegativeStock === "N") {
          setProductStock(true);
          return false;
        }
      }

      return true;
    }
  } catch (error) {
    console.error("Error checking stock:", error);
    return false;
  }
};
